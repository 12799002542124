import Breadcrumb from "../../common/breadcrumb";
import DropdownSelect from "../../base/DropDown/Dropdown";
import { useEffect, useState } from "react";
import { BsChevronBarUp, BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettingtData } from "../../../redux/settings/settingAction";
import ReactTimePicker from "react-time-picker";
import LoaderOverlay from "../../loader/loader";
import { HttpService } from "../../../services/httpService";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

const IAQSettings = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.setting);
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState({
    account_id: 3,
  });
  const [inheritedValues, setInheritedValues] = useState({
    account_id: 3,
  });
  const { data, loading, error } = state;
  const interval = ["Daily", "Weekly", "Monthly"];
  const [value, setValue] = useState(null);
  const [schedule, setSchedule] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const onChange = (time) => {
    setValue(time);
  };
  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    dispatch(fetchSettingtData());
  }, []);

  useEffect(() => {
    if (data) {
      setModel(data);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setInheritedValues(data);
    }
  }, []);

  console.log(data, "settingData");
  console.log(model, "settingData");
  const fillModel = (key, val) => {
   
    setModel((prevModel) => ({
      ...prevModel,
      [key]: val,
      account_id : 3
    }));
  };
  const postSettings = async () => {
    setIsLoading(true);
    try {
      const response = await HttpService.CoreApiPost("settings/create", model);
      console.log(response);
      toast.success("Settings Updated Successfully");
      dispatch(fetchSettingtData());
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  function handleInputChange(event) {
    const { name, value, type, checked } = event.target;

    // Check if the input type is a checkbox
    if (type === "checkbox") {
      // For checkboxes, use the 'checked' property to determine the state
      fillModel(name, checked);
    } else {
      // For other input types, use the 'value' property
      fillModel(name, value);
    }
  }

  console.log({ model });
  console.log(model?.alert_sun_from, "sunday time");

  const timeChanging = (name, selectedTime) => {
    const [hoursStr, minutesStr] = selectedTime?.split(":");
    const hours = parseInt(hoursStr);
    const minutes = parseInt(minutesStr);
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    console.log(`${formattedHours}:${formattedMinutes} ${ampm} timing check`);
    fillModel(name, `${formattedHours}:${formattedMinutes} ${ampm}`);
  };
  console.log(model?.alert_sub_active);
  function convert12HourTo24Hour(timeString) {
    console.log(timeString, "time string");
    const [time, period] = timeString?.split(" ");
    let [hours, minutes] = time?.split(":");

    if (period === "pm" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    }

    if (period === "am" && hours === "12") {
      hours = "00";
    }

    return `${hours}:${minutes}`;
  }
  function handleCheckboxChange(name, event) {
    if (event && event.target) {
      const isChecked = event.target.checked;
      setModel((prevModel) => ({ ...prevModel, [name]: isChecked })); // Use computed property name
    } else {
      console.error("Event or event.target is undefined.");
    }
  }

  return (
    <>
      {loading ? (
        <LoaderOverlay />
      ) : (
        <>
          <Breadcrumb parent="" title="" />
          <div className="bg-[#fff] p-4 px-6 rounded-lg">
            <div>
              {/* <p><span className="text-[25px] font-medium">Settings </span>Learn how to set up a <span className="text-[#1EC2E5]">Site Level Setting </span> for your monitoring devices</p> */}
              <p className="mt-3 text-lg font-medium">Setting Name</p>
            </div>
            <div className=" gap-3 mt-1">
              <button className=" text-black  rounded-md flex items-center">
                <input
                  checked
                  type="radio"
                  name="component"
                  id="prior-period"
                  className="h-[24px] w-[24px] mr-2 appearance-none accent-[#1ec2e5] bg-gray-200 border-2 border-gray-300 rounded-md p-1 checked:bg-[#1EC2E5] checked:text-white"
                />
                <label
                  className="mt-1 text-lg font-medium"
                  htmlFor="prior-period"
                >
                  Site Level Setting{" "}
                </label>
              </button>

              <button className=" text-black  rounded-md flex items-center">
                <input
                  type="radio"
                  name="component"
                  id="date"
                  className="h-[24px] w-[24px] mr-2 p-1 appearance-none accent-[#1ec2e5] bg-gray-200 border-2 border-gray-300 rounded-md checked:bg-[#1EC2E5] checked:text-white"
                />
                <label className="mt-1 text-lg font-medium" htmlFor="date">
                  Add New Custom Setting
                </label>
              </button>
            </div>
            <div className="mt-4 flex gap-2">
              <button
                onClick={postSettings}
                className="bg-[green] text-[#fff] p-2 px-4 w-[160px]  rounded-md text-lg "
              >
                Save{" "}
                {isLoading ? <Spinner className="mt-1 ms-2" size="sm" /> : ""}
              </button>
              <button className="bg-[#f4f7fedd] text-[#ff0000] p-2 px-4 w-[284px] rounded-md text-lg ">
                Revert to Site Level Default
              </button>
            </div>
          </div>
          <div className="bg-[#fff] p-4 px-6 mt-3 rounded-lg">
            <p className=" text-[25px] font-medium">Site Level Setting</p>
            <p className="mt-3 text-lg font-medium">General Settings</p>
            <div className="flex gap-3 mt-3 flex-wrap">
              <div className="flex-grow">
                <p className="text-lg font-medium">
                  Alert Notification Contact
                </p>
                <input
                  className="bg-[#F4F7FE] rounded-full w-full p-[11px]   px-5 mt-3"
                  name="alert_notification_contact"
                  value={model?.alert_notification_contact}
                  onChange={handleInputChange}
                  placeholder={`${inheritedValues?.alert_notification_contact} (inherited)`}
                />
              </div>
              <div className="flex-grow">
                <p className="text-lg font-medium">Alert Interval (Hours)</p>
                <input
                  name="alert_interval"
                  className="bg-[#F4F7FE] rounded-full w-full p-[11px]  px-5 mt-3"
                  value={model?.alert_interval}
                  onChange={handleInputChange}
                  placeholder={`${inheritedValues?.alert_interval} (inherited)`}
                />
              </div>
              <div className="flex-grow">
                <p className="text-lg font-medium">
                  Downtime Threshold Alert (Hours)
                </p>
                <input
                  name="down_time_thershold"
                  className="bg-[#F4F7FE] rounded-full w-full p-[11px]  px-5 mt-3"
                  value={model?.down_time_thershold}
                  onChange={handleInputChange}
                  placeholder={`${inheritedValues?.down_time_thershold} (inherited)`}
                />
              </div>
              <div className="flex-grow">
                <p className="text-lg font-medium">
                  IAQ Report Delivery Schedule
                </p>
                <div className="mt-3">
                  <DropdownSelect
                    width="w-full"
                    data={interval}
                    title="Select Schedule"
                    onChange={setSchedule}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#fff] p-4 px-6 rounded-lg mt-3">
            <p className="mt-3 text-[25px] font-medium">
              Specify Alert Periods
            </p>
            <div className="grid border grid-cols-2 md:grid-cols-4 mt-3 flex-wrap items-center p-3 gap-2">
              <div className="flex-grow">
                <p className="text-lg font-medium">Day : Sun</p>
              </div>
              <div className="flex-grow">
                <div className="flex items-normal gap-1">
                  <p className="text-lg font-medium">Active</p>
                  <p className="flex items-center">
                    <input
                      checked={model?.alert_sub_active}
                      onChange={(event) =>
                        handleCheckboxChange("alert_sub_active", event)
                      }
                      className="h-[24px] w-[24px] ml-2  appearance-none accent-[#1ec2e5] bg-gray-200 border-2 border-gray-300 rounded-md p-1 checked:bg-[#1EC2E5] checked:text-white"
                      type="checkbox"
                    />
                  </p>
                </div>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  From :
                  <ReactTimePicker
                    className="ms-2 rounded-md"
                    format="hh:mm a"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_sun_from", selectedTime);
                    }}
                    value={
                      model?.alert_sun_from
                        ? convert12HourTo24Hour(model?.alert_sun_from)
                        : ""
                    }
                    name="alert_sun_from"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>

              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  To :
                  <ReactTimePicker
                    format="hh:mm a"
                    className="ml-2 rounded-md"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_sun_to", selectedTime);
                    }}
                    value={
                      model?.alert_sun_to
                        ? convert12HourTo24Hour(model?.alert_sun_to)
                        : ""
                    }
                    name="alert_sun_to"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
            </div>
            <div className="grid border grid-cols-2 md:grid-cols-4 mt-3 flex-wrap items-center p-3 gap-2">
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">Day : Mon</p>
              </div>
              <div className="flex-grow">
                <div className="flex items-normal gap-1">
                  <p className="text-sm md:text-lg font-medium">Active </p>
                  <p className="flex items-center">
                    <input
                      checked={model?.alert_mon_active} // Use checked attribute instead of value
                      onChange={(event) =>
                        handleCheckboxChange("alert_mon_active", event)
                      }
                      className="h-[24px] w-[24px] ml-2 appearance-none accent-[#1ec2e5] bg-gray-200 border-2 border-gray-300 rounded-md p-1 checked:bg-[#1EC2E5] checked:text-white"
                      type="checkbox"
                    />
                  </p>
                </div>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  From :
                  <ReactTimePicker
                    format="hh:mm a"
                    className="ms-2 rounded-md"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_mon_from", selectedTime);
                    }}
                    value={
                      model?.alert_mon_from
                        ? convert12HourTo24Hour(model?.alert_mon_from)
                        : ""
                    }
                    name="alert_mon_from"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  To :{" "}
                  <ReactTimePicker
                    className=" rounded-md"
                    format="hh:mm a"
                    onChange={(selectedTime) => {
                      // console.log(timeString);
                      timeChanging("alert_mon_to", selectedTime);
                    }}
                    value={
                      model?.alert_mon_to
                        ? convert12HourTo24Hour(model?.alert_mon_to)
                        : ""
                    }
                    name="alert_mon_to"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
            </div>
            <div className="grid border grid-cols-2 md:grid-cols-4 mt-3 flex-wrap items-center p-3 gap-2">
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">Day : Tues</p>
              </div>
              <div className="flex-grow">
                <div className="flex items-normal gap-1">
                  <p className="text-sm md:text-lg font-medium">Active </p>
                  <p className="flex items-center">
                    <input
                      checked={model?.alert_tues_active}
                      onChange={(event) =>
                        handleCheckboxChange("alert_tues_active", event)
                      }
                      className="h-[24px] w-[24px] ml-2 appearance-none accent-[#1ec2e5] bg-gray-200 border-2 border-gray-300 rounded-md p-1 checked:bg-[#1EC2E5] checked:text-white"
                      type="checkbox"
                    />
                  </p>
                </div>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  From :
                  <ReactTimePicker
                    className="ms-2 rounded-md"
                    format="hh:mm a"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_tues_from", selectedTime);
                    }}
                    value={
                      model?.alert_tues_from
                        ? convert12HourTo24Hour(model?.alert_tues_from)
                        : ""
                    }
                    name="alert_tues_from"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  To :{" "}
                  <ReactTimePicker
                    className="ms-2 rounded-md"
                    format="hh:mm a"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_tues_to", selectedTime);
                    }}
                    value={
                      model?.alert_tues_to
                        ? convert12HourTo24Hour(model?.alert_tues_to)
                        : ""
                    }
                    name="alert_tues_to"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
            </div>
            <div className="grid border grid-cols-2 md:grid-cols-4 mt-3 flex-wrap items-center p-3 gap-2">
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">Day : Wed</p>
              </div>
              <div className="flex-grow">
                <div className="flex items-normal gap-1">
                  <p className="text-sm md:text-lg font-medium">Active </p>
                  <p>
                    <p className="flex items-center">
                      <input
                        checked={model?.alert_wed_active}
                        onChange={(event) =>
                          handleCheckboxChange("alert_wed_active", event)
                        }
                        className="h-[24px] w-[24px] ml-2 appearance-none accent-[#1ec2e5] bg-gray-200 border-2 border-gray-300 rounded-md p-1 checked:bg-[#1EC2E5] checked:text-white"
                        type="checkbox"
                      />
                    </p>
                  </p>
                </div>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  From :
                  <ReactTimePicker
                    className="ms-2 rounded-md"
                    format="hh:mm a"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_wed_from", selectedTime);
                    }}
                    value={
                      model?.alert_wed_from
                        ? convert12HourTo24Hour(model?.alert_wed_from)
                        : ""
                    }
                    name="alert_wed_from"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  To :
                  <ReactTimePicker
                    className="ml-2 rounded-md"
                    format="hh:mm a"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_wed_to", selectedTime);
                    }}
                    value={
                      model?.alert_wed_to
                        ? convert12HourTo24Hour(model?.alert_wed_to)
                        : ""
                    }
                    name="alert_wed_to"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
            </div>
            <div className="grid border grid-cols-2 md:grid-cols-4 mt-3 p-3 gap-2">
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">Day : Thur</p>
              </div>
              <div className="flex-grow">
                <div className="flex items-normal gap-1">
                  <p className="text-sm md:text-lg font-medium">Active </p>
                  <p>
                    <p className="flex items-center">
                      <input
                        checked={model?.alert_thurs_active}
                        onChange={(event) =>
                          handleCheckboxChange("alert_thurs_active", event)
                        }
                        className="h-[24px] w-[24px] ml-2 appearance-none accent-[#1ec2e5] bg-gray-200 border-2 border-gray-300 rounded-md p-1 checked:bg-[#1EC2E5] checked:text-white"
                        type="checkbox"
                      />
                    </p>
                  </p>
                </div>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  From :
                  <ReactTimePicker
                    className="ms-2 rounded-md"
                    format="hh:mm a"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_thurs_from", selectedTime);
                    }}
                    value={
                      model?.alert_thurs_from
                        ? convert12HourTo24Hour(model?.alert_thurs_from)
                        : ""
                    }
                    name="alert_thurs_from"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  To :{" "}
                  <ReactTimePicker
                    className="ms-2 rounded-md"
                    format="hh:mm a"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_thurs_to", selectedTime);
                    }}
                    value={
                      model?.alert_thurs_to
                        ? convert12HourTo24Hour(model?.alert_thurs_to)
                        : ""
                    }
                    name="alert_thurs_to"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
            </div>
            <div className="grid border grid-cols-2 md:grid-cols-4 mt-3 flex-wrap items-center p-3 gap-2">
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">Day : Fri</p>
              </div>
              <div className="flex-grow">
                <div className="flex items-normal gap-1">
                  <p className="text-sm md:text-lg font-medium">Active </p>
                  <p>
                    <p className="flex items-center">
                      <input
                        checked={model?.alert_fri_active}
                        onChange={(event) =>
                          handleCheckboxChange("alert_fri_active", event)
                        }
                        className="h-[24px] w-[24px] ml-2 appearance-none accent-[#1ec2e5] bg-gray-200 border-2 border-gray-300 rounded-md p-1 checked:bg-[#1EC2E5] checked:text-white"
                        type="checkbox"
                      />
                    </p>
                  </p>
                </div>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  From :
                  <ReactTimePicker
                    className="ms-2 rounded-md"
                    format="hh:mm a"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_fri_from", selectedTime);
                    }}
                    value={
                      model?.alert_fri_from
                        ? convert12HourTo24Hour(model?.alert_fri_from)
                        : ""
                    }
                    name="alert_fri_from"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  To :{" "}
                  <ReactTimePicker
                    className="ms-2 rounded-md"
                    format="hh:mm a"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_fri_to", selectedTime);
                    }}
                    value={
                      model?.alert_fri_to
                        ? convert12HourTo24Hour(model?.alert_fri_to)
                        : ""
                    }
                    name="alert_fri_to"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
            </div>
            <div className="grid border grid-cols-2 md:grid-cols-4 mt-3 flex-wrap items-center p-3 gap-2">
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">Day : Sat</p>
              </div>
              <div className="flex-grow">
                <div className="flex items-normal gap-1">
                  <p className="text-sm md:text-lg font-medium">Active </p>
                  <p>
                    <p className="flex items-center">
                      <input
                        checked={model?.alert_sat_active}
                        onChange={(event) =>
                          handleCheckboxChange("alert_sat_active", event)
                        }
                        className="h-[24px] w-[24px] ml-2 appearance-none accent-[#1ec2e5] bg-gray-200 border-2 border-gray-300 rounded-md p-1 checked:bg-[#1EC2E5] checked:text-white"
                        type="checkbox"
                      />
                    </p>
                  </p>
                </div>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  From :
                  <ReactTimePicker
                    className="ms-2 rounded-md"
                    format="hh:mm a"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_sat_from", selectedTime);
                    }}
                    value={
                      model?.alert_sat_from
                        ? convert12HourTo24Hour(model?.alert_sat_from)
                        : ""
                    }
                    name="alert_sat_from"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
              <div className="flex-grow">
                <p className="text-sm md:text-lg font-medium">
                  To :{" "}
                  <ReactTimePicker
                    className="ms-2 rounded-md"
                    format="hh:mm a"
                    onChange={(selectedTime) => {
                      console.log(selectedTime);
                      timeChanging("alert_sat_to", selectedTime);
                    }}
                    value={
                      model?.alert_sat_to
                        ? convert12HourTo24Hour(model?.alert_sat_to)
                        : ""
                    }
                    name="alert_sat_to"
                    clearIcon={null} // Hide the clear icon if you don't need it
                    disableClock={true} // Hide the clock button if you don't need it
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <div
              className={`flex mt-3 justify-between rounded-lg bg-white accordion-item-header px-4 py-4 cursor-pointer hover:bg-gray-100 ${
                activeIndex === 3 ? "bg-gray-100" : ""
              }`}
              onClick={() => toggleAccordion(0)}
            >
              <div>Particle Allergens (PM 2.5)</div>
              <div>
                {activeIndex == 0 ? <BsChevronUp /> : <BsChevronDown />}
              </div>
            </div>
            {activeIndex === 0 && (
              <div className="accordion-item-content bg-white mt-3 px-4 py-4 mb-3">
                <div className="border p-3 ">
                  <div className=" border p-2">
                    Sensor Reporting Range: 0 - 50 ug/m3
                  </div>
                  <div className="grid grid-cols-2">
                    <p className=" border p-2">Alert Criteria (Standard)*</p>
                    <p className=" border p-2">Indoor Air Quality Rating</p>
                    <p className=" border p-2">0 - 15 ug/m3, daily average</p>
                    <p className=" border p-2">Acceptable</p>
                    <p className=" border p-2"> 15 - 35 ug/m3, daily average</p>
                    <p className=" border p-2"> Action Recommended</p>
                    <p className=" border p-2">35 ug/m3, daily average</p>
                    <p className=" border p-2"> Action Necessary</p>
                  </div>
                  <div className="mt-1">
                    <span className="font-bold">Rationale: </span>
                    Acceptable particulate matter (PM 2.5) values are based on
                    the lack of two major indoor PM sources: environmental
                    tobacco smoke and cooking. Extensive evidence shows that
                    even limited exposure to low PM levels can trigger or worsen
                    asthma, allergies, or other symptoms in sensitive
                    populations. Chronic exposures are highly correlated to
                    chronic lung disease and reduced lung capacity in both
                    adults and children. Short- and long-term PM exposure at any
                    level can result in respiratory, eye, and mucous membrane
                    irritation for any population, but sensitive groups are
                    shown to be definitively at risk for marked adverse affects.
                  </div>
                  <div className="mt-1">
                    <span className="font-bold">Standard: </span>
                    alert criteria is based on scientific literature produced by
                    WELL Building Standard and EPA annual exposure standard.
                  </div>
                  <div className="border p-2 mt-3">
                    <p>Specify Your PM 2.5 Settings:</p>
                    <div className="grid grid-cols-4 gap-2">
                      <div>
                        <p className="text-xs ">Action Recommended</p>
                        <input
                          name="pm_2_5_action_recommended"
                          className="mt-1 focus:border-[#000]-[#000] border p-1   w-full"
                          value={model?.pm_2_5_action_recommended}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <p className="text-xs">Action Necessary</p>
                        <input
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          name="pm_2_5_action_alert_necessary"
                          onChange={handleInputChange}
                          value={model?.pm_2_5_action_alert_necessary}
                        />
                      </div>
                      <div>
                        <p className="text-xs">Alert Threshold</p>
                        <input
                          name="pm_2_5_action_alert_trigger_threshold"
                          onChange={handleInputChange}
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.pm_2_5_action_alert_trigger_threshold}
                        />
                      </div>
                      <div>
                        <p className="text-xs">
                          Alert Period to Average Over (mins)
                        </p>
                        <input
                          onChange={handleInputChange}
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.pm_2_5_action_alert_pertiod_to_avg_over}
                          name="pm_2_5_action_alert_pertiod_to_avg_over"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className={`flex mt-3 justify-between rounded-lg bg-white accordion-item-header px-4 py-4 cursor-pointer hover:bg-gray-100 ${
                activeIndex === 3 ? "bg-gray-100" : ""
              }`}
              onClick={() => toggleAccordion(1)}
            >
              <div>Carbon Dioxide (CO2)</div>
              <div>
                {activeIndex == 1 ? <BsChevronUp /> : <BsChevronDown />}
              </div>
            </div>
            {activeIndex === 1 && (
              <div className="accordion-item-content bg-white mt-3 px-4 py-4 mb-3">
                <div className="border p-3 ">
                  <div className=" border p-2">
                    Sensor Reporting Range: 0 - 3000 PPM
                  </div>
                  <div className="grid grid-cols-2">
                    <p className=" border p-2">Alert Criteria (Standard)*</p>
                    <p className=" border p-2">Indoor Air Quality Rating</p>
                    <p className=" border p-2">0 - 1000 ppm, daily average</p>
                    <p className=" border p-2">Acceptable</p>
                    <p className=" border p-2">
                      1001 - 2500 ppm, daily average
                    </p>
                    <p className=" border p-2"> Action Recommended</p>
                    <p className=" border p-2">2500 ppm, daily average</p>
                    <p className=" border p-2"> Action Necessary</p>
                  </div>
                  <div className="mt-1">
                    <span className="font-bold">Rationale: </span>
                    Acceptable carbon dioxide (CO2) levels are based on ambient
                    backgrounds levels measured outdoors. Concentrations above
                    ambient (300-450 ppm) generally occur from human occupancy
                    and activity combined with lack of adequate ventilation.
                    This can lead to health and comfort issues such as fatigue
                    and/or a perception of "stale air." Above 1000 ppm daily
                    average, CO2 can cause dizziness, fatigue, and headache for
                    much of the population.
                  </div>
                  <div className="mt-1">
                    <span className="font-bold">Standard: </span>
                    alert criteria is based on scientific literature produced by
                    ASHRAE Standard 55-2010, National Institute for Occupational
                    Safe and Health (NIOSH) and National Institutes of Health
                    (NIH).
                  </div>
                  <div className="border p-2 mt-3">
                    <p>Specify Your Carbon Dioxide Settings:</p>
                    <div className="grid grid-cols-4 gap-2">
                      <div>
                        <p className="text-xs ">Action Recommended</p>
                        <input
                          name="co_2_action_recommened_threshold"
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.co_2_action_recommened_threshold}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <p className="text-xs">Action Necessary</p>
                        <input
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          name="co_2_action_necessary"
                          onChange={handleInputChange}
                          value={model?.co_2_action_necessary}
                        />
                      </div>
                      <div>
                        <p className="text-xs">Alert Threshold</p>
                        <input
                          name="co_2_alert_trigger_threshold"
                          onChange={handleInputChange}
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.co_2_alert_trigger_threshold}
                        />
                      </div>
                      <div>
                        <p className="text-xs">
                          Alert Period to Average Over (mins)
                        </p>
                        <input
                          onChange={handleInputChange}
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.co_2_alert_period_to_avg_over}
                          name="co_2_alert_period_to_avg_over"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className={`flex mt-3 justify-between rounded-lg bg-white accordion-item-header px-4 py-4 cursor-pointer hover:bg-gray-100 ${
                activeIndex === 3 ? "bg-gray-100" : ""
              }`}
              onClick={() => toggleAccordion(2)}
            >
              <div>Carbon Monoxide (CO)</div>
              <div>
                {activeIndex == 2 ? <BsChevronUp /> : <BsChevronDown />}
              </div>
            </div>
            {activeIndex === 2 && (
              <div className="accordion-item-content bg-white mt-3 px-4 py-4 mb-3">
                <div className="border p-3 ">
                  <div className=" border p-2">
                    Sensor Reporting Range: 0 - 3000 PPM
                  </div>
                  <div className="grid grid-cols-2">
                    <p className=" border p-2">Alert Criteria (Standard)*</p>
                    <p className=" border p-2">Indoor Air Quality Rating</p>
                    <p className=" border p-2">0 - 9 ppm, 8-hour averagee</p>
                    <p className=" border p-2">Acceptable</p>
                    <p className=" border p-2">9 - 35 ppm, 8-hour averagee</p>
                    <p className=" border p-2"> Action Recommended</p>
                    <p className=" border p-2">
                      35 ppm, 1-hour average AND/OR 9 ppm, 8-hour average
                    </p>
                    <p className=" border p-2"> Action Necessary</p>
                  </div>
                  <div className="mt-1">
                    <span className="font-bold">Rationale: </span>
                    Acceptable carbon monoxide (CO) levels are based on adverse
                    affects on human health from prolonged exposure to high
                    levels of CO. Carbon monoxide is made naturally in the body,
                    with COHb blood levels found at up to 2.5% without sign of
                    adverse effects. Above 5 ppm, COHb levels increase above
                    2.5%, which can adversely affect cardiovascular disease
                    sufferers as well as pregnant women and their unborn child.
                    Healthy individuals will be impacted at levels of 12-20 ppm,
                    including reducing breathing capacity among other effects.
                    CO occurring at above 20 ppm for a 1-hr average has a severe
                    impact on sensitive groups and significantly impacts healthy
                    individuals as well. At high levels, carbon monoxide can
                    cause death.
                  </div>
                  <div className="mt-1">
                    <span className="font-bold">Standard: </span>
                    alert criteria is based on scientific literature produced by
                    WELL Building Standard, World Health Organization and
                    National Institute for Occupational Safe and Health (NIOSH).
                  </div>
                  <div className="border p-2 mt-3">
                    <p>Specify Your Carbon Dioxide Settings:</p>
                    <div className="grid grid-cols-4 gap-2">
                      <div>
                        <p className="text-xs ">Action Recommended</p>
                        <input
                          name="co_action_recommened_threshold"
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.co_action_recommened_threshold}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <p className="text-xs">Action Necessary</p>
                        <input
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          name="co_action_necessary"
                          onChange={handleInputChange}
                          value={model?.co_action_necessary}
                        />
                      </div>
                      <div>
                        <p className="text-xs">Alert Threshold</p>
                        <input
                          name="co_alert_trigger_threshold"
                          onChange={handleInputChange}
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.co_alert_trigger_threshold}
                        />
                      </div>
                      <div>
                        <p className="text-xs">
                          Alert Period to Average Over (mins)
                        </p>
                        <input
                          onChange={handleInputChange}
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.co_alert_period_to_avg_over}
                          name="co_alert_period_to_avg_over"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className={`flex mt-3 justify-between rounded-lg bg-white accordion-item-header px-4 py-4 cursor-pointer hover:bg-gray-100 ${
                activeIndex === 3 ? "bg-gray-100" : ""
              }`}
              onClick={() => toggleAccordion(3)}
            >
              <div>Chemical Pollutants (TVOC)</div>
              <div>
                {activeIndex == 3 ? <BsChevronUp /> : <BsChevronDown />}
              </div>
            </div>
            {activeIndex === 3 && (
              <div className="accordion-item-content bg-white mt-3 px-4 py-4 mb-3">
                <div className="border p-3 ">
                  <div className=" border p-2">
                    Sensor Reporting Range: 0 - 4000 ug/m3
                  </div>
                  <div className="grid grid-cols-2">
                    <p className=" border p-2">Alert Criteria (Standard)*</p>
                    <p className=" border p-2">Indoor Air Quality Rating</p>
                    <p className=" border p-2">0 - 500 ug/m3, daily average</p>
                    <p className=" border p-2">Acceptable</p>
                    <p className=" border p-2">
                      501 - 1000 ug/m3, daily average
                    </p>
                    <p className=" border p-2"> Action Recommended</p>
                    <p className=" border p-2">3 1000 ug/m3, daily average</p>
                    <p className=" border p-2"> Action Necessary</p>
                  </div>
                  <div className="mt-1">
                    <span className="font-bold">Rationale: </span>
                    Acceptable total volatile organic compound (TVOC) values are
                    based on threshold response levels to mixtures of compounds.
                    At low levels, VOCs are known to trigger or worsen asthma,
                    allergy, and other adverse systems in sensitive populations.
                    Respiratory, eye, skin, and mucous membrane irritation can
                    occur. Some VOCs are known carcinogens. Both short- and
                    long-term exposure to VOCs create risk for both sensitive
                    and healthy populations, with total responses and percent of
                    affected population increasing in conjunction with VOC level
                    increases.
                  </div>
                  <div className="mt-1">
                    <span className="font-bold">Standard: </span>
                    alert criteria is based on scientific literature produced by
                    WELL Building Standard and ASHRAE Draft Standard 62-1989R.
                  </div>
                  <div className="border p-2 mt-3">
                    <p>Specify Your Chemical Settings::</p>
                    <div className="grid grid-cols-4 gap-2">
                      <div>
                        <p className="text-xs ">Action Recommended</p>
                        <input
                          name="chem_recommended_threshold"
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.chem_recommended_threshold
                            }
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <p className="text-xs">Action Necessary</p>
                        <input
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          name="chem_action_necessary"
                          onChange={handleInputChange}
                          value={model?.chem_action_necessary}
                        />
                      </div>
                      <div>
                        <p className="text-xs">Alert Threshold</p>
                        <input
                          name="chem_alert_trigger_threshold"
                          onChange={handleInputChange}
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.chem_alert_trigger_threshold}
                        />
                      </div>
                      <div>
                        <p className="text-xs">
                          Alert Period to Average Over (mins)
                        </p>
                        <input
                          onChange={handleInputChange}
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.chem_alert_period_to_avg_over}
                          name="chem_alert_period_to_avg_over"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className={`flex mt-3 justify-between rounded-lg bg-white accordion-item-header px-4 py-4 cursor-pointer hover:bg-gray-100 ${
                activeIndex === 3 ? "bg-gray-100" : ""
              }`}
              onClick={() => toggleAccordion(4)}
            >
              <div>Temperature (°F)</div>
              <div>
                {activeIndex == 4 ? <BsChevronUp /> : <BsChevronDown />}
              </div>
            </div>
            {activeIndex === 4 && (
              <div className="accordion-item-content bg-white mt-3 px-4 py-4 mb-3">
                <div className="border p-3 ">
                  <div className=" border p-2">
                    Sensor Reporting Range: 32°F - 100°F
                  </div>
                  <div className="grid grid-cols-2">
                    <p className="border p-2">Alert Criteria (Standard)*</p>
                    <p className="border p-2">Indoor Air Quality Rating</p>
                    <p className="border p-2">73°F - 79°F</p>
                    <p className="border p-2">Acceptable</p>
                    <p className="border p-2">&lt;73°F or &gt;79°F</p>
                    <p className="border p-2">Action Necessary</p>
                  </div>

                  <div className="border font-bold p-2">TEMPERATURE WINTER</div>
                  <div className=" border p-2">
                    Sensor Reporting Range: 32°F - 100°F
                  </div>
                  <div className="grid grid-cols-2">
                    <p className=" border p-2">Alert Criteria (Standard)*</p>
                    <p className=" border p-2">Indoor Air Quality Rating</p>
                    <p className=" border p-2">68°F - 74°F</p>
                    <p className=" border p-2">Acceptable</p>
                    <p className=" border p-2">
                      &lt;68°F or &gt;74°F Action Necessary Rationale:
                      Temperatures that are
                    </p>
                    <p className=" border p-2"> Action Necessary</p>
                  </div>
                  <div className="mt-1">
                    <span className="font-bold">Rationale: </span>
                    Temperatures that are too high, too low, or inconsistent can
                    stress the body as well as cause discomfort. Sensitive
                    groups may be immediately affected by temperature
                    fluctuations as little as a single degree. Potential impact
                    on human comfort of limited (1 day or more) or extended
                    (duration of test) temperature values outside the acceptable
                    range support recommended and/or necessary action, as well
                    as indicate poorly functioning, absent, or improperly
                    installed equipment.
                  </div>
                  <div className="mt-1">
                    <span className="font-bold">Standard: </span>
                    alert criteria is based on scientific literature produced by
                    ASHRAE Standard 55-2010.
                  </div>
                  <div className="border p-2 mt-3">
                    <p>Specify Your Temperature Settings:</p>
                    <div className="grid grid-cols-5 gap-2">
                      <div>
                        <p className="text-xs ">Summer Low</p>
                        <input
                          name="temp_summer_action_low_threshold"
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.temp_summer_action_low_threshold}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <p className="text-xs">Summer High</p>
                        <input
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          name="temp_summer_action_high_threshold"
                          onChange={handleInputChange}
                          value={model?.temp_summer_action_high_threshold}
                        />
                      </div>
                      <div>
                        <p className="text-xs">Winter Low</p>
                        <input
                          name="temp_winter_action_low_threshold"
                          onChange={handleInputChange}
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.temp_winter_action_low_threshold}
                        />
                      </div>
                      <div>
                        <p className="text-xs">Winter High</p>
                        <input
                          onChange={handleInputChange}
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.temp_winter_action_high_threshold}
                          name="temp_winter_action_high_threshold"
                        />
                      </div>
                      <div>
                        <p className="text-xs">Alert Period to Average Over</p>
                        <input
                          onChange={handleInputChange}
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.temp_alert_period_to_avg_over}
                          name="temp_alert_period_to_avg_over"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="accordion-item">
            <div
              className={`flex mt-3 justify-between rounded-lg bg-white accordion-item-header px-4 py-4 cursor-pointer hover:bg-gray-100 ${
                activeIndex === 3 ? "bg-gray-100" : ""
              }`}
              onClick={() => toggleAccordion(5)}
            >
              <div>Humidity (RH)</div>
              <div>
                {activeIndex == 5 ? <BsChevronUp /> : <BsChevronDown />}
              </div>
            </div>
            {activeIndex === 5 && (
              <div className="accordion-item-content bg-white mt-3 px-4 py-4 mb-3">
                <div className="border p-3 ">
                  <div className=" border p-2">
                    Sensor Reporting Range: 10% - 90%
                  </div>
                  <div className="grid grid-cols-2">
                    <p className=" border p-2">Alert Criteria (Standard)*</p>
                    <p className=" border p-2">Indoor Air Quality Rating</p>
                    <p className=" border p-2">30% - 55%**</p>
                    <p className=" border p-2">Acceptable</p>
                    <p className=" border p-2">
                      &lt;30% or &gt;55% for 1 hour or more**
                    </p>
                    <p className=" border p-2">
                      Action Recommended for Sensitive Groups
                    </p>
                    <p className=" border p-2">
                      &lt;30% or &gt;55% for 1 day or more**
                    </p>
                    <p className=" border p-2">Action Recommended</p>

                    <p className=" border p-2">
                      &lt;30% or &gt;55% for duration of test**
                    </p>
                    <p className=" border p-2">Action Necessary</p>
                  </div>
                  <div className="mt-1">
                    <span className="font-bold">Rationale: </span>
                    Acceptable relative humidity (RH) values are based on both
                    comfort and health reasons. Sensitive groups may be exposed
                    to conditions where respiratory, skin, or other conditions
                    are exacerbated via dryness, moistness, and/or microbial
                    growth. Prolonged exposure for all populations to low level
                    RH (&lt;25%) and high levels (&gt;60%) can increase the risk
                    of microbial growth.
                  </div>
                  <div className="mt-1">
                    <span className="font-bold">Standard: </span>
                    alert criteria is based on scientific literature produced by
                    ASHRAE Standard 55-2010.
                  </div>
                  <div className="border p-2 mt-3">
                    <p>Specify Your Humidity Settings:</p>
                    <div className="grid grid-cols-3 gap-2">
                      <div>
                        <p className="text-xs ">Low</p>
                        <input
                          name="humidity_action_low_threshold"
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.humidity_action_low_threshold}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <p className="text-xs">High</p>
                        <input
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          name="humidity_action_high_threshold"
                          onChange={handleInputChange}
                          value={model?.humidity_action_high_threshold}
                        />
                      </div>
                      <div>
                        <p className="text-xs">Alert Period to Average Over</p>
                        <input
                          name="humidity_alert_period_to_avg_over"
                          onChange={handleInputChange}
                          className="mt-1 border p-1 focus:border-[#000] w-full"
                          value={model?.humidity_alert_period_to_avg_over}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default IAQSettings;
