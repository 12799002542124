import React, { useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import DropdownSelect from "../../base/DropDown/Dropdown";
import 'react-time-picker/dist/TimePicker.css';
import ReactDatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import GaugeComponent from "react-gauge-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchDates, fetchLocationData } from "../../../redux/location/locationAction";
import { fetchDeviceListData } from "../../../redux/deviceList/deviceListAction";
import moment from "moment";
import { HttpService } from "../../../services/httpService";
import LoaderOverlay from "../../loader/loader";
import { isEmpty } from 'lodash';
import { toast } from "react-toastify";

const Analytics = () => {
    const [location, setLocation] = useState([]);
    const locations = useSelector((state) => state?.deviceList);
    const [loading, setLoading] = useState(false);
    const [dates, setDates] = useState([]);
    const [airData, setAirData] = useState({})
    const [date, setDate] = useState("");
    const [component, setComponent] = useState("prior-period")
    const [startDate, setStartDate] = useState("");
    const dispatch = useDispatch();
    const [filterLoading, setFilterLoading] = useState(false)

    // State for the selected date initialized to the previous day's date
    const [selectedDate, setSelectedDate] = useState(new Date());
    useEffect(() => {
        dispatch(fetchLocationData());
        dispatch(fetchDeviceListData());
        onload();
    }, []);

    useEffect(() => {
        if (locations) {
            setLocation(locations[0]?.id)
        }
    }, [locations])

    const filterDate = (dateString) => {
        const formattedDate = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
        return formattedDate
    }

    const onload = async () => {
        const data = await fetchDates();
        setDates(data);
    };

    useEffect(() => {
        filterDate(selectedDate)
    }, [selectedDate])

    const handleFilter = async () => {
        setFilterLoading(true); // Set loading to true at the start of the function
        try {
            const response = await HttpService.CoreApiPost(`assets/analytics`, {
                filters: {
                    locations: location?.length ? location :[ locations?.data[0]?.id],  // this is actually the device ID
                    end_date: selectedDate || null,
                    prior_period: component === "date" ? (date || null) : (date || "1 day"),
                    start_date: component === "prior-period" ? null : startDate,
                },
            });
            setAirData(response?.data?.body);
            toast.success("Filter Applied Successfully"); // Show success message only if the API call is successful
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error(error?.response?.data?.message || "Something went wrong")
            setAirData({})
        } finally {
            setFilterLoading(false); // Set loading to false after the fetch is complete
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if ( locations && locations.data && locations.data.length > 0) {
                const locationId = locations.data[0].id;
                setLoading(true); // Set loading to true before starting the fetch
                try {
                    const filters = {
                        locations: location?.length ? location : [locationId],  // this is actually the device ID
                        end_date: selectedDate || null,
                        prior_period: component === "date" ? (date || null) : (date || "1 day"),
                    };
                    const response = await HttpService.CoreApiPost(`assets/analytics`, { filters });
                    setAirData(response?.data?.body);
                } catch (error) {
                    console.error("Error fetching data:", error);
                    toast.error(error?.response?.data?.message || "Something went wrong")
                    setAirData({})
                } finally {
                    setLoading(false); // Set loading to false after the fetch is complete
                }
            } else {
                console.log("No locations found.");
            }
        };
        fetchData();
    }, [locations]);

    return (
        <>
            {loading ? <LoaderOverlay /> : <div>
                <>
                    <Breadcrumb parent="" title="" />
                    <div className="bg-[#fff] p-5 rounded-md">
                        <div className="flex items-center gap-2">
                        <p className="text-xl font-semibold">Device:</p>
                        <div className="  mt-2 w-full">
                                <select
                                onChange={(e)=>setLocation([e.target.value] )}
                            
                                // onChange={(val) => props.onChange(val.target.value)}
                                className={`bg-[#f4f7fedd] rounded-full p-2 px-5 text-lg w-full`}
                                >
                                {locations?.data?.map((e, index) => {
                                    return (
                                        <option value={e?.id} key={index}>
                                        {e?.locations?.name && e?.device_id
                                            ? `${e?.locations?.name}, ${e?.device_id}`
                                            : e?.locations?.name || e?.device_id || "Not Found"}
                                    </option>
                                    );
                                })}
                                </select>
                        </div>
                    </div>
                        <div className="block md:flex gap-3 mt-3 px-4">
                            <button onClick={() => {
                                setDate("")
                                setSelectedDate(new Date());
                                setStartDate("")
                                setComponent("prior-period")
                            }} className={` ${component === "prior-period" ? "bg-[#1EC2E5] text-white" : "bg-[#f4f7fedd] text-black"} p-2 px-4 rounded-md flex items-center mt-1 md:mt-0 `}>
                                <input type="radio" name="component" id="prior-period" className="h-4 w-4 mr-2 appearance-none accent-[#1ec2e5] bg-gray-200 border-2 border-gray-300 rounded-md p-1 checked:bg-[#fff] checked:border-transparent" checked={component === "prior-period"} onChange={() => setComponent("prior-period")} />
                                <label className="mt-2" htmlFor="prior-period">Look Back</label>
                            </button>
                            <button onClick={() => {
                                setDate("")
                                setSelectedDate(new Date())
                                setStartDate(new Date())
                                setComponent("date")
                            }} className={` ${component === "date" ? "bg-[#1ec2e5] text-white" : "bg-[#f4f7fedd] text-black"} mt-1 md:mt-0 p-2 px-4 rounded-md flex items-center  `}>
                                <input type="radio" name="component" id="date" className="h-4 w-4 mr-2 p-1 appearance-none accent-[#1ec2e5] bg-gray-200 border-2 border-gray-300 rounded-md checked:bg-[#fff] checked:border-transparent" checked={component === "date"} onChange={() => setComponent("date")} />
                                <label className="mt-2" htmlFor="date">Date Range</label>
                            </button>
                        </div>
                        <div>
                            {component === "prior-period" && <div className="flex flex-col lg:flex-row lg:justify-start lg:items-center mt-5 space-x-10">
                                <div className="block mt-2 lg:mt-0 md:flex gap-2 items-center">
                                    <p className="text-xl font-semibold">Look back over:</p>
                                    <DropdownSelect
                                        onChange={setDate}
                                        data={dates}
                                        title="1 day"
                                    />
                                </div>
                                <div className="block md:flex mt-2 lg:mt-0  items-center gap-2">
                                    <p className="text-xl font-semibold">Until:</p>
                                    <div className="input-group max-w-64 flex gap-4 bg-[#F4F7FE] p-2 px-4 rounded-2xl cursor-pointer ">
                                        <div
                                            onClick={() =>
                                                document
                                                    .querySelector(
                                                        ".react-datepicker-wrapper > .react-datepicker__input-container > input"
                                                    )
                                                    .click()
                                            }
                                            className=""
                                        >
                                            <FaCalendarAlt className="text-[#A5B4CB] text-lg" />
                                        </div>
                                        <ReactDatePicker
                                            className="bg-transparent "
                                            selected={selectedDate}

                                            onChange={(date) => {

                                                setSelectedDate(date)
                                            }}
                                            dateFormat="MM/dd/yyyy" // Customize date format if needed
                                        />
                                    </div>
                                </div>
                            </div>}
                        </div>
                        {component === "date" &&
                            <div className="flex flex-col md:flex-row gap-4 md:items-center mt-5">
                                <div className="flex items-center gap-2">
                                    <p className="text-xl font-semibold">From:</p>
                                    <div className="input-group flex gap-4 bg-[#F4F7FE] p-2 px-4 rounded-2xl cursor-pointer ">
                                        <div
                                            onClick={() => document.querySelector('.react-datepicker-wrapper > .react-datepicker__input-container > input').click()}
                                            className=""
                                        >
                                            <FaCalendarAlt className="text-[#A5B4CB] text-lg" />
                                        </div>
                                        <ReactDatePicker
                                            className="bg-transparent "
                                            selected={startDate}
                                            onChange={date => setStartDate(date)}
                                             dateFormat="MM/dd/yyyy" // Customize date format if needed
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <p className="text-xl font-semibold">To:</p>
                                    <div className="input-group flex gap-4 bg-[#F4F7FE] p-2 px-4 rounded-2xl cursor-pointer ">
                                        <div
                                            onClick={() => document.querySelector('.react-datepicker-wrapper > .react-datepicker__input-container > input').click()}
                                            className=""
                                        >
                                            <FaCalendarAlt className="text-[#A5B4CB] text-lg" />
                                        </div>
                                        <ReactDatePicker
                                            className="bg-transparent "
                                            selected={selectedDate}
                                            minDate={new Date(startDate)}
                                            disabled={startDate ? false : true}
                                            onChange={(date) => {

                                                setSelectedDate(date)
                                            }}
                                             dateFormat="   MM/dd/yyyy" // Customize date format if needed
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        <button
                            className="bg-[green] text-[#fff] p-2 px-5 rounded-md mt-3"
                            onClick={handleFilter}
                            disabled={filterLoading}
                        >
                            {filterLoading ? <p className="text-white">Loading...</p> : <p className="text-white">Apply Filter</p>}
                        </button>
                    </div>
                </>
                {isEmpty(airData) ? <p className="text-center bg-[#fff] p-5 font-bold text-5xl">No Data Found</p> : 
                <div className="bg-[#fff] p-2 md:p-5 rounded-md mt-3 mb-3">
                    <div>
                        <p className="bg-[#1EC2E5] text-[#fff] text-lg font-semibold p-2 text-center">Particulate Matter(PM 2.5)</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-center p-4">
                            <div className="w-full md:w-[80%] block mx-auto">
                                <div>
                                    <p className="font-semibold  text-center">Low : {airData?.pm25?.lowValue} , High : {airData?.pm25?.highValue}</p>
                                    <p className="font-semibold  text-center">({airData?.pm25?.readings} readings over {airData?.pm25?.mins})</p>
                                </div>
                                <GaugeComponent
                                    type="semicircle"
                                    minValue={airData && airData?.pm25.min}                                
                                    maxValue={airData && airData?.pm25.max}
                                    arc={{
                                        width: 0.2,
                                        padding: 0.005,
                                        cornerRadius: 1,
                                        subArcs: airData && airData?.pm25?.data
                                    }}
                                    pointer={airData && airData?.pm25.pointer} 
                                    value={airData && airData?.pm25?.value}
                                    labels={airData?.humidity?.labels}
                                />
                                <div className="flex items-center flex-wrap  gap-3 mt-5 justify-center">
                                    {airData?.pm25?.legendaArray?.length ? airData?.pm25?.legendaArray?.map((e) => {
                                        return (
                                            <div className="flex items-center gap-1">
                                                <p
                                                    className="w-4 h-4 inline-block rounded-full"
                                                    style={{ backgroundColor: e?.color }}
                                                ></p>
                                                <p className="text-sm">{e?.text}</p>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>
                            <div className="p-2">
                                <p className="text-[#0000ff] font-medium ">CAUSE OF ELEVATED 2.PM</p>
                                <ul className="list-disc ">
                                    <li>Human Activities</li>
                                    <li>Outdoor Particles Migrate Indoors (Construction Sites/Exhaust/Wildfire)</li>
                                    <li>Cooking, Cigarette Smoking/Unvented Space Heaters within Space</li>
                                    <li>Inadequate Natural or Mechanical Ventilation Sources</li>
                                </ul>
                                <p className="text-[#0000ff] font-medium">HOW CAN I REGULATE MY PM 2.5 LEVELS</p>
                                <ul className="list-disc ">
                                    <li>Vent All Fuel Fired Combustion Appliances</li>
                                    <li>Install and Use Appropriate Sized Exhaust Fans</li>
                                    <li>Have Trained Professional Inspect, Clean, and Tune up Mechanical System</li>
                                    <li>Install Proper Filters and Change as Per Manufacture Specs</li>
                                    <li>Set Fan On or to Circulation</li>
                                </ul>
                                <p className="text-[#0000ff] font-medium  ">BUILDING & HEALTH INFORMATION FOR PM 2.5 LEVELS</p>
                                <ul className="list-disc ">
                                    <li>EPA.GOV/ASHRAE.GOV/ENERGY.GOV</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="bg-[#1EC2E5] text-[#fff] text-lg font-semibold p-2 text-center mt-3">Volatile Organic Compounds (VOCs)</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-center p-4">
                            <div className="w-full md:w-[80%] block mx-auto">
                                <div>
                                    <p className="font-semibold  text-center">Low : {airData?.chem?.lowValue} , High : {airData?.chem?.highValue}</p>
                                    <p className="font-semibold  text-center">({airData?.chem?.readings} readings over {airData?.chem?.mins})</p>
                                </div>
                                <GaugeComponent
                                    type="semicircle"
                                    maxValue={airData && airData?.chem.max}
                                    arc={{
                                        width: 0.2,
                                        padding: 0.005,
                                        cornerRadius: 1,
                                        subArcs: airData && airData?.chem?.data
                                    }}
                                    pointer={airData && airData?.chem.pointer} 
                                    value={airData && airData?.chem?.value}
                                    labels={airData?.humidity?.labels}
                                />
                                <div className="flex items-center gap-3 mt-5 justify-center">
                                    {airData?.chem?.legendaArray?.length ? airData?.chem?.legendaArray?.map((e) => {
                                        return (
                                            <div className="flex items-center gap-1">
                                                <p
                                                    className="w-4 h-4 inline-block rounded-full"
                                                    style={{ backgroundColor: e?.color }}
                                                ></p>
                                                <p className="text-sm">{e?.text}</p>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>
                            <div className="p-2">
                                <p className="text-[#0000ff] font-medium ">CAUSE OF ELEVATED VCOS</p>
                                <ul className="list-disc ">
                                    <li>Storage and Install of Construction Materials/Painting/Carpet/and Cleaning Supplies</li>
                                    <li>Temperature and Humidity Fluctuations</li>
                                    <li>Human Activities</li>
                                    <li>Cooking and Smoking within Space</li>
                                    <li>Cleaning and Maintenance with VOC Chemical Cleaners</li>
                                </ul>
                                <p className="text-[#0000ff] font-medium">HOW CAN I REGULATE MY VCOS</p>
                                <ul className="list-disc ">
                                    <li>Increase Natural or Mechanical Ventilation</li>
                                    <li>Store and Or Dispose of all High VOC Products</li>
                                    <li>Clean Frequently Using Low or No VOC Products</li>

                                </ul>
                                <p className="text-[#0000ff] font-medium  ">BUILDING & HEALTH INFORMATION FOR VCOS</p>
                                <ul className="list-disc ">
                                    <li>EPA.GOV/ASHRAE.GOV/ENERGY.GOV</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="bg-[#1EC2E5] text-[#fff] text-lg font-semibold p-2 text-center mt-3">Carbon Dioxide (CO2)</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-center p-4">
                            <div className="w-full md:w-[80%] block mx-auto">
                                <div>
                                    <p className="font-semibold  text-center">Low : {airData?.co2?.lowValue} , High : {airData?.co2?.highValue}</p>
                                    <p className="font-semibold  text-center">({airData?.co2?.readings} readings over {airData?.co2?.mins})</p>
                                </div>
                                {<GaugeComponent
                                    type="semicircle"
                                    maxValue={airData && airData?.co2.max}
                                    arc={{
                                        width: 0.2,
                                        padding: 0.005,
                                        cornerRadius: 1,
                                        subArcs: airData && airData?.co2?.data
                                    }}
                                    pointer={airData && airData?.co2.pointer} 
                                    value={airData && airData?.co2?.value}
                                    labels={airData?.humidity?.labels}
                                />}
                                <div className="flex items-center gap-3 mt-5 justify-center">
                                    {airData?.co2?.legendaArray?.length ? airData?.co2?.legendaArray?.map((e) => {
                                        return (
                                            <div className="flex items-center gap-1">
                                                <p
                                                    className="w-4 h-4 inline-block rounded-full"
                                                    style={{ backgroundColor: e?.color }}
                                                ></p>
                                                <p className="text-sm">{e?.text}</p>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>
                            <div className="p-2">
                                <p className="text-[#0000ff] font-medium ">CAUSE OF ELEVATED CO2</p>
                                <ul className="list-disc ">
                                    <li>Overcrowding in Poorly Ventilated Rooms</li>
                                    <li>Smoking & Open Flames</li>
                                    <li>Human Activities</li>
                                </ul>
                                <p className="text-[#0000ff] font-medium">HOW CAN I REGULATE MY CO2</p>
                                <ul className="list-disc ">
                                    <li>Ventilation with Fresh Air</li>
                                    <li>Increase Natural or Mechanical Ventilation</li>

                                </ul>
                                <p className="text-[#0000ff] font-medium  ">BUILDING & HEALTH INFORMATION FOR CO2</p>
                                <ul className="list-disc ">
                                    <li>EPA.GOV/ASHRAE.GOV/ENERGY.GOV</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="bg-[#1EC2E5] text-[#fff] text-lg font-semibold p-2 text-center mt-3">Temperature (°F)</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-center p-4">
                            <div className="w-full md:w-[80%] block mx-auto">
                                <div>
                                    <p className="font-semibold  text-center">Low : {airData?.temp?.lowValue} , High : {airData?.temp?.highValue}</p>
                                    <p className="font-semibold  text-center">({airData?.temp?.readings} readings over {airData?.temp?.mins})</p>
                                </div>
                                <GaugeComponent
                                    type="semicircle"
                                    maxValue={airData && airData?.temp.max}
                                    arc={{
                                        width: 0.2,
                                        padding: 0.005,
                                        cornerRadius: 1,
                                        subArcs: airData && airData?.temp?.data
                                    }}
                                    pointer={airData && airData?.temp.pointer} 
                                    value={airData && airData?.temp?.value}
                                    labels={airData?.humidity?.labels}
                                />
                                <div className="flex items-center gap-3 mt-5 justify-center">
                                    {airData?.temp?.legendaArray?.length ? airData?.temp?.legendaArray?.map((e) => {
                                        return (
                                            <div className="flex items-center gap-1">
                                                <p
                                                    className="w-4 h-4 inline-block rounded-full"
                                                    style={{ backgroundColor: e?.color }}
                                                ></p>
                                                <p className="text-sm">{e?.text}</p>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>
                            <div className="p-2">
                                <p className="text-[#0000ff] font-medium ">CAUSE OF LOW & HIGH TEMP</p>
                                <ul className="list-disc ">
                                    <li>Faulty Thermostat and Or HVAC Equipment</li>
                                    <li>Weak Air Flow / Filter Issues</li>
                                    <li>Sealed Windows / Properly Insulated Spaces</li>
                                    <li>SInspect all Ductwork</li>
                                </ul>
                                <p className="text-[#0000ff] font-medium">HOW CAN I REGULATE MY TEMP LEVELS</p>
                                <ul className="list-disc ">
                                    <li>Check Vents and Registers</li>
                                    <li>Check for Air Leaks</li>
                                    <li>Toggle for Blowing Fans</li>
                                    <li>Set Thermostat to Schedule</li>

                                </ul>
                                <p className="text-[#0000ff] font-medium  ">BUILDING & HEALTH INFORMATION FOR TEMP LEVELS</p>
                                <ul className="list-disc ">
                                    <li>EPA.GOV/ASHRAE.GOV/ENERGY.GOV</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="bg-[#1EC2E5] text-[#fff] text-lg font-semibold p-2 text-center mt-3">Relative Humidity (RH)</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-center p-4">
                            <div className="w-full md:w-[80%] block mx-auto">
                                <div>
                                    <p className="font-semibold  text-center">Low : {airData?.humidity?.lowValue} , High : {airData?.humidity?.highValue}</p>
                                    <p className="font-semibold  text-center">({airData?.humidity?.readings} readings over {airData?.humidity?.mins})</p>
                                </div>
                                {<GaugeComponent
                                    type="semicircle"
                                    maxValue={airData && airData?.humidity.max}
                                    arc={{
                                        width: 0.2,
                                        padding: 0.005,
                                        cornerRadius: 1,
                                        subArcs: airData && airData?.humidity?.data
                                    }}
                                    pointer={airData && airData?.humidity.pointer} 
                                    value={airData && airData?.humidity?.value}
                                    labels={airData?.humidity?.labels}
                                />}
                                <div className="flex items-center gap-3 mt-5 justify-center">
                                    {airData?.humidity?.legendaArray?.length ? airData?.humidity?.legendaArray?.map((e) => {
                                        return (
                                            <div className="flex items-center gap-1">
                                                <p
                                                    className="w-4 h-4 inline-block rounded-full"
                                                    style={{ backgroundColor: e?.color }}
                                                ></p>
                                                <p className="text-sm">{e?.text}</p>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>
                            <div className="p-2">
                                <p className="text-[#0000ff] font-medium ">CAUSE OF RELATIVE HUMIDITY</p>
                                <ul className="list-disc ">
                                    <li>Poor Ventilation</li>
                                    <li>Moisture from Breathing, Cooking & Cleaning</li>
                                    <li>Household Activities</li>
                                    <li>Possible Oversized HVAC System</li>
                                </ul>
                                <p className="text-[#0000ff] font-medium">HOW CAN I REGULATE MY RELATIVE HUMIDITY</p>
                                <ul className="list-disc ">
                                    <li>Install Dehumidifiers/Humidification</li>
                                    <li>Raise Temp of Cold Surfaces where Moisture is Present</li>
                                    <li>Use Insulation or Storm Windows</li>
                                    <li>Fix Leaking Pipes</li>
                                    <li>Ensure Exhaust Fans are Working Properly</li>
                                    <li>Inspect Building Envelope</li>

                                </ul>
                                <p className="text-[#0000ff] font-medium  ">BUILDING & HEALTH INFORMATION FOR RELATIVE HUMIDITY</p>
                                <ul className="list-disc ">
                                    <li>EPA.GOV/ASHRAE.GOV/ENERGY.GOV</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="bg-[#1EC2E5] text-[#fff] text-lg font-semibold p-2 text-center mt-3">Carbon Monoxide (CO)</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-center p-4">
                            <div className="w-full md:w-[80%] block mx-auto">
                                <div>
                                    <p className="font-semibold  text-center">Low : {airData?.co?.lowValue} , High : {airData?.co?.highValue}</p>
                                    <p className="font-semibold  text-center">({airData?.co?.readings} readings over {airData?.co?.mins})</p>
                                </div>
            
                                <GaugeComponent
                                    type="semicircle"
                                    minValue={0}                          
                                    maxValue={airData && airData?.co.max}                          
                                    arc={{
                                        width: 0.2,
                                        padding: 0.005,
                                        cornerRadius: 1,
                                        subArcs: airData && airData?.co?.data
                                    }}
                                    pointer={airData && airData?.co.pointer} 
                                    value={airData && airData?.co?.value}
                                    labels={airData?.humidity?.labels}
                                />
                                <div className="flex items-center gap-3 mt-5 justify-center">
                                    {airData?.co?.legendaArray?.length ? airData?.co?.legendaArray?.map((e) => {
                                        return (
                                            <div className="flex items-center gap-1">
                                                <p
                                                    className="w-4 h-4 inline-block rounded-full"
                                                    style={{ backgroundColor: e?.color }}
                                                ></p>
                                                <p className="text-sm">{e?.text}</p>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>
                            <div className="p-2">
                                <p className="text-[#0000ff] font-medium ">CAUSE OF CARBON MONOXIDE</p>
                                <ul className="list-disc ">
                                    <li>Fires/Exhaust/Tobacco</li>
                                    <li>Incorrectly Installed or Poorly Maintained or Ventilated Appliances</li>
                                    <li>Faulty Fuel Burning Appliances</li>
                                    <li>Cracked Heat Exchanger</li>
                                    <li>Parking Garage Ventilation</li>
                                </ul>
                                <p className="text-[#0000ff] font-medium">HOW CAN I REGULATE MY RELATIVE CARBON MONOXIDE</p>
                                <ul className="list-disc ">
                                    <li>Check for Gas Leaks</li>
                                    <li>Install Carbon Monoxide Detectors</li>
                                    <li>Keep Gas Powered Equipment Away from Windows</li>
                                    <li>Inspect All Gas Fired Equipment</li>
                                    <li>Inspect that Vents to Outdoors are not Obstructed</li>
                                    <li>Inspect Building Envelope</li>

                                </ul>
                                <p className="text-[#0000ff] font-medium  ">BUILDING & HEALTH INFORMATION FOR CARBON MONOXIDE</p>
                                <ul className="list-disc ">
                                    <li>EPA.GOV/ASHRAE.GOV/ENERGY.GOV</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>}
        </>
    )
}

export default Analytics;
