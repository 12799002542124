import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    fetchSettingStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSettingSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
    },
    fetchSettingFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchSettingStart, fetchSettingSuccess, fetchSettingFailure } = settingSlice.actions;

export default settingSlice.reducer;
