import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import { formatDate } from "../dateConversion";
import { useEffect, useState } from "react";
import { ActionRecommended, ActionNecessary } from "../../constant";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const horizontalDottedLine1 = {
  id: "horizontalDottedLine1",
  beforeDatasetsDraw(chart, args, plugins) {
    const {
      ctx,
      options,
      chartArea: { top, right, bottom, left, width, height },
      scales: { x, y },
    } = chart;
    ctx.save();

    function drawLine(lineThickness, lineColor, yCoor) {
      ctx.beginPath();
      ctx.strokeStyle = lineColor;
      ctx.lineWidth = lineThickness;
      ctx.moveTo(left, y.getPixelForValue(yCoor));
      ctx.lineTo(right, y.getPixelForValue(yCoor));
      ctx.setLineDash([10, 5]);
      ctx.stroke();
    }
    drawLine(2, "red", 35);
    drawLine(2, "yellow", 15);

    //1. draw a line
    // ctx.strokeRect(0,y.getPixelForValue(35),200,0)
    // x0 = starting point of the line horizontal l/r
    // y0 = starting point of the line in vertical position t/b
    // x1 = ending point of the line horizontal l/r
    // y1 = ending point of the line in vertical position t/b

    // 2. draw the line at the exact position
  },
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    //   arbitraryLines:{
    //     lineColor:['green','yellow','red'],
    //   }
  },

  // title: {
  //   display: true,
  //   text: 'Chart.js Line Chart',
  //   position:'right'
  // },

  //   },
};

// const json = {
//   label: [
//     "4/16 6:00",
//     "4/16 10:00",
//     "4/16 18:00",
//     "4/16 22:00",
//     "4/17 2:00",
//     "4/17 2:00",
//     "4/17 6:00",
//   ],
//   data: [
//     {
//       title: "StarBucks Port Jeff-back of house",
//       values: [
//         10, 22, 34, 53, 44, 22, 67, 86, 89, 53, 44, 22, 67, 86, 33, 42, 47, 78,
//         33,
//       ],
//       color: "blue",
//       lineWidth: 1,
//     },
//     {
//       title: "StarBucks Port Jeff-Front of house",
//       values: [
//         10, 4, 22, 67, 86, 22, 34, 53, 44, 22, 67, 86, 89, 33, 42, 47, 78, 33,
//       ],
//       color: "green",
//     },
//     {
//       title: "StarBucks PatchHogue-Front of house",
//       values: [
//         ,
//         44,
//         10,
//         22,
//         34,
//         53,
//         22,
//         67,
//         47,
//         78,
//         86,
//         89,
//         33,
//         42,
//         10,
//         22,
//         34,
//         53,
//         33,
//       ],
//       color: "purple",
//     },
//     {
//       title: "StarBucks Patchogue-Back  of house",
//       values: [10, 67, 86, 89, 33, 42, 47, 78, 22, 34, 53, 44, 22, 33],
//       color: "black",
//     },
//     {
//       title: "Recommended",
//       values: [],
//       color: "yellow",
//     },
//     {
//       title: "Necessary",
//       values: [],
//       color: "red",
//     },
//   ],
// };

// export const data = {
//   labels: json.label,
//   datasets: json.data.map((item) => {
//     let colorCode = item.color || faker.color.rgb();
//     return {
//       label: item.title,
//       data: item.values,
//       borderColor: colorCode,
//       backgroundColor: colorCode,
//       borderWidth: 1,
//       // tension:0.1
//     };
//   }),
// };

const LineChart = ({ json }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia('(max-width: 768px)').matches);

  //   let json = {
  //     "data": {
  //         "data": [
  //             {
  //                 "title": "Action recommended",
  //                 "values": [
  //                     22,
  //                 ],
  //                 "lineWidth": 1,
  //                 "color": "yellow"
  //             },
  //             {
  //                 "title": "Action Necessary",
  //                 "values": [
  //                     2420,
  //                     2420
  //                 ],
  //                 "lineWidth": 1,
  //                 "color": "orange"
  //             },
  //             {
  //                 "title": "401000237",
  //                 "values": [],
  //                 "lineWidth": 1,
  //                 "color": "blue"
  //             }
  //         ]
  //     },
  //     "label": [
  //         "08/05 08:28",
  //         "08/05 08:58",
  //     ]
  // }
  let dataObj = {
    labels: json?.label, // json?.label.map(formatDate),
    datasets: json?.data?.data?.map((item, index) => {
      let colorCode = item.color || faker.color.rgb();
      let dataset = {
        label: item.title, ///json?.label[index],
        data: item.values,
        borderColor: colorCode,
        backgroundColor: colorCode,
        borderWidth: 1,
        // tension:0.1
      };
      if (item.title === ActionRecommended.text || item.title === ActionNecessary.text ||
        item.title === 'High' || item.title === 'Low'
      ) {
        dataset.borderColor = [5, 10];
        dataset.borderWidth = 0.1
        //dataset.pointStyle = 'line';
      }

      return dataset;
    }),
  };
  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: isSmallScreen ? 'bottom' : 'right',
        labels: {
          usePointStyle: true, 
          pointStyle: 'roundedRect', 
      }
      },
    },
    /*
    scales: {
      x: {
        type: 'time',
          time: {
            unit: 'hour',
            stepSize: 4,
            displayFormats: {
              hour: 'M/d H:mm'
          },
          ticks: {
            maxRotation: 30,
            minRotation: 30,
          },
        },
      },
    },
    */
  };

  useEffect(() => {
    // Function to check if the screen is small
    const checkScreenSize = () => {
      setIsSmallScreen(window.matchMedia('(max-width: 768px)').matches);
    };
    checkScreenSize();

    // Listen for window resize events
    window.addEventListener('resize', checkScreenSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []); 
  //   if (json.data.length === 0) {
  //     return null;
  //   }
  /*
  dataObj.datasets[0]['borderDash']=[
    5,
    10
];
dataObj.datasets[0]['fill']=false;
dataObj.datasets[0]['borderWidth']=3;
*/
  return (
    <Line options={lineOptions} data={dataObj}
    //  plugins={[horizontalDottedLine1]}
      />
  );
};

export default LineChart;
