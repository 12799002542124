import React, { useState, useEffect } from "react";
import logo from "../assets/images/brand-airify.png";
import man from "../assets/images/user/1.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Login, YourName, Password, RememberMe } from "../constant";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { AuthService } from "../services/authService";
import UserService from "../services/userservice";

const Signin = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));

  useEffect(() => {
    if (value !== null) localStorage.setItem("profileURL", value);
    else localStorage.setItem("profileURL", man);
  }, [value]);

  const loginAuth = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const {data} = await AuthService.login(email, password);
      console.log(data?.body?.token);
      UserService.SaveAccessToken(data?.body?.token)
      


      navigate('/dashboard');
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const googleAuth = async () => {
    navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
  };

  const facebookAuth = async () => {
  navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
  };
  const twitterAuth = async () => {
    navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
  };
  const githubAuth = async () => {
    navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="grid md:grid-cols-2 items-center">
              <div className="flex justify-center">
                <div >
                  <div  >
                    <img className="block mx-auto mt-2" src={logo} />
                  </div>
                  <div className="text-2xl   lg:text-[35px] leading-10  mt-5 text-center md:mt-2">
                    Airify Making the Invisible Visible
                  </div>

                </div>
              </div>
              <div className="">

                <div className="auth-innerright">
                  <div className="authentication-box">

                    <div className=" mt-4">
                      <div className="">
                        <div className="text-center">
                          <p className="text-xl font-semibold">{Login}</p>

                        </div>
                        <form onSubmit={loginAuth} className="theme-form p-4">
                          <div className="form-group">
                            <label className="col-form-label pt-0">{YourName}</label>
                            <input className="form-control py-3" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email address" />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">{Password}</label>
                            <input className="form-control py-3" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                          </div>
                          {/* <div className="checkbox p-0">
                            <input id="checkbox1" type="checkbox" />
                            <label htmlFor="checkbox1">{RememberMe}</label>
                          </div> */}
                          <div className="form-group form-row mt-3 mb-0 d-grid">
                            <Button  className="py-3 flex items-center" variant="info" type="submit" disabled={loading}>
                              Log In {loading && <span><Spinner size="sm" className="ms-3 text-base text-[#fff]"/></span>}
                            </Button>
                          </div>
                          <div className="mt-2">By continuing, you agree to the Terms of use and Privacy Policy. </div>
                          <div className="social mt-3">
                            {/* Social login buttons */}
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default Signin;
