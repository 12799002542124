import axios from "axios";
import { API_URL } from "../url/url";
import { HttpService } from "./httpService";


export class AuthService {
  static async login(email, password) {
    return await axios.post(`${API_URL}/users/login`, { email, password }, {
      headers: {
        "api_key": '90d11774-611a-4712-a2ba-0f3e509d225c',
        'Content-Type': 'application/json'
      }
    });
}



  static signup(email, password) {
    return HttpService.CoreApiPost("users", {
      email,
      password,
    });
  }


}


