import React, { Component } from "react";
import Default from "../components/dashboard/defaultCompo/default";

// Import custom Components

// // widgets
// import General from "../components/widgets/general";
// import Chart from "../components/widgets/chart";

// // Base
// import Typography from "../components/base/typography";
// import Avatar from "../components/base/avatar";
// import HelperClass from "../components/base/helperclass";
// import Grid from "../components/base/grid";
// import TagsandPills from "../components/base/tagsandpills";
// import ProgressBar from "../components/base/progressBar";
// import ModalComponent from "../components/base/modalComponent";
// import AlertComponent from "../components/base/Alert/Alert";
// import PopoverComponent from "../components/base/Popover/Popover";
// import TooltipsComponent from "../components/base/tooltipsComponent";
// import Spinner from "../components/base/spinner";
// import DropdownComponent from "../components/base/DropDown/Dropdown";
// import TabBootstrap from "../components/base/Tabs/TabBootstrap";
// import TabLine from "../components/base/Tabs/TabLine";
// import Accordion from "../components/base/Accordian/Accordian";
// import Navs from "../components/base/Nav/Navs";
// import List from "../components/base/lists/list";
// import Shadow from "../components/base/shadow";

// // Advance
// import Scrollable from "../components/advance/scrollable";
// import Toastr from "../components/advance/toastr";
// import RatingComponent from "../components/advance/ratingComponent";
// import DropzoneComponent from "../components/advance/dropzone";
// import TourComponent from "../components/advance/tourComponent";
// import SweetAlert from "../components/advance/sweetAlert";
// import SlickSlider from "../components/advance/slickSlider";
// import CarouselComponent from "../components/advance/Carousels/carouselComponent";
// import Ribbon from "../components/advance/ribbon";
// import Pagination from "../components/advance/pagination";
// import Steps from "../components/advance/steps";
// import UIBreadCrumb from "../components/advance/uibreadcrumb";
// import RangeSlider from "../components/advance/rangeSlider";
// import ImageCropper from "../components/advance/imageCropper";
// import StickyNotes from "../components/advance/stickyNotes";
// import DragNDropComp from "../components/advance/drag-n-drop/dragNDropComp";
// import UploadImage from "../components/advance/uploadImage";

// // icons
// import FlagIcons from "../components/icons/flagIcons";
// import FontAwsomeIcon from "../components/icons/fontAwsomeIcon";
// import IcoIcons from "../components/icons/icoIcons";
// import ThemifyIcons from "../components/icons/themifyIcons";
// import FeatherIcons from "../components/icons/featherIcons";
// import WeatherIcons from "../components/icons/weatherIcons";

// // buttons
// import DefaultBtn from "../components/buttons/default-btn";
// import FlatBtn from "../components/buttons/flatBtn";
// import EdgeBtn from "../components/buttons/edgeBtn";
// import RaisedBtn from "../components/buttons/raisedBtn";
// import GroupBtn from "../components/buttons/groupBtn";

// // gallery
// import ImageGallery from "../components/gallery/imageGallery";
// import ImageHover from "../components/gallery/imageHover";
// import ImageWithDesc from "../components/gallery/imageWithDesc";
// import MesonryGallery from "../components/gallery/mesonryGallery";
// import MesonryDesc from "../components/gallery/mesonryDesc";

// // forms
// import FormValidation from "../components/forms/form-control/form-validation";
// import BaseInput from "../components/forms/form-control/baseInput";
// import RadioCheckbox from "../components/forms/form-control/radio-checkbox";
// import InputGroupComp from "../components/forms/form-control/inputGroup";
// import MegaOptions from "../components/forms/form-control/megaOptions";

// import DatepickerComponent from "../components/forms/form-widgets/datepickerComponent";
// import TypeaheadComp from "../components/forms/form-widgets/typeaheadComponent/typeahead";

// import FormDefault from "../components/forms/form-layout/formDefault";
// import FormWizard from "../components/forms/form-layout/wizard/form-wizard";

// // tables
// import BasicTable from "../components/tables/bootstrap/basicTable";
// import StylingTable from "../components/tables/bootstrap/stylingTable";
// import BorderTable from "../components/tables/bootstrap/borderTable";
// import SizingTable from "../components/tables/bootstrap/sizingTable";
// import DataTableComponent from "../components/tables/dataTableComponent";

// // cards
// import BasicCards from "../components/cards/basicCards";
// import CreativeCards from "../components/cards/creativeCards";
// import TabCard from "../components/cards/tabCard";

// // timeline
// import Timeline2 from "../components/timelines/timeline2";
// import Timeline from "../components/timelines/timeline";

// // charts
// import GoogleChart from "../components/charts/googleChart";
// import ChartJs from "../components/charts/chartJs";
// import ApexCharts from "../components/charts/apex-chart";

// // maps
// import GoogleMap from "../components/map/googleMap";
// import LeafletMapComp from "../components/map/leafletMap";

// // editors
// import Editor1 from "../components/editor/ckEditor";
// import Editor2 from "../components/editor/mdeEditor";
// import Editor3 from "../components/editor/aceCodeEditor";

// // users
// import UserProfile from "../components/users/userProfile";
// import UserEdit from "../components/users/userEdit";
// import UserCards from "../components/users/user-cards";

// // Calender
// import Calender1 from "../components/calender/calender1";
// import Calender2 from "../components/calender/calender2";

// // blog
// import BlogDetail from "../components/blog/blogDetail";
// import BlogSingle from "../components/blog/blogSingle";
// import BlogPost from "../components/blog/blogPost";

// // job search
// import CardView from "../components/jobSearch/cardView";
// import JobList from "../components/jobSearch/job-list";
// import JobDetail from "../components/jobSearch/job-detail";
// import JobApply from "../components/jobSearch/job-apply";

// // learning
// import LearningList from "../components/learning/learning-list";
// import LearningDeatil from "../components/learning/learning-deatil";

// // Import Applications Components
// import Todo from "../components/applications/todo-app/todo";
// import EmailDefault from "../components/applications/email-app/emailDefault";
// import Chat from "../components/applications/chat-app/chat";
// import EcommerceApp from "../components/applications/ecommerce-app/product";
// import AddToCart from "../components/applications/ecommerce-app/add-to-cart";
// import WishlistComponent from "../components/applications/ecommerce-app/wishlist";
// import ProductDetail from "../components/applications/ecommerce-app/product-detail/product-detail";
// import Invoice from "../components/applications/ecommerce-app/invoice";
// import Checkout from "../components/applications/ecommerce-app/checkout";
// import ContactApp from "../components/applications/contact-app/contactApp";
// import ProductList from "../components/applications/ecommerce-app/product-list";
// import Payment from "../components/applications/ecommerce-app/payment";
// import History from "../components/applications/ecommerce-app/history";
// import BookmarkApp from "../components/applications/bookmark-app/bookmarkApp";
// import TaskApp from "../components/applications/task-app/taskApp";

// import ProjectApp from "../components/applications/project/project";
// import NewProject from "../components/applications/project/new-project";
// import ProjectDetails from "../components/applications/project/project-details";
// import FileManager from "../components/applications/file-manager/file-manager";

// import SocialApp from "../components/social-app/socialApp";
// import FaqComponent from "../components/faq/faqComponent";
// import KnowledgebaseComponent from "../components/knowledgebase/knowledgebaseComponent";
// import SupportTicket from "../components/support-ticket/supportTicket";

// // search page
// import Searchpage from "../components/search/searchpage";

// // sample page
// import Samplepage from "../components/sample/samplepage";

// // Pricing
// import Pricing from "../components/price/pricing";
import AlertDetail from "../components/dashboard/alertDetail/alertDetail";
import DeviceList from "../components/dashboard/deviceList/deviceList";
import DeviceDetail from "../components/dashboard/deviceList/deviceDetail/devicedetail";
import ReportFilter from "../components/dashboard/reportFilter/reportFilter";
import Analytics from "../components/dashboard/analytics/analytics";
import Location from "../components/dashboard/location/location";
import IAQSettings from "../components/dashboard/settings/setting";


// const Test  = () => <h1>Test</h1> // mene test component use kia hai koi use krlo header sidebar sath aega aur isko thora neeche lakr dihaye heading ko ?


export const routes = [
  { path: `/dashboard`, Component: <Default /> },
  { path: `/alertdetails`, Component: <AlertDetail />, name: "Dashboard > Alert Detail" },
  { path: `/devicelist`, Component: <DeviceList /> },
  { path: `/sensor`, Component: <ReportFilter /> },
  { path: "/devices/detail/:account_id", Component: <DeviceDetail />, name: "Device Detail" },
  { path: "/iaqanalytics", Component: <Analytics /> },
  { path: "/location", Component: <Location />},
  { path: "/settings", Component: <IAQSettings /> }
  // { path: `/dashboard/ecommerce`, Component: <Ecommerce /> },
  // { path: `/dashboard/university`, Component: <University /> },
  // { path: `/dashboard/crypto`, Component: <Crypto /> },
  // { path: `/dashboard/server`, Component: <ServerComponent /> },
  // { path: `/dashboard/project`, Component: <Project /> },

  // { path: `/widgets/general`, Component: <General /> },
  // { path: `/base/typography`, Component: <Typography /> },
  // { path: `/base/avatar`, Component: <Avatar /> },
  // { path: `/base/grid`, Component: <Grid /> },
  // { path: `/base/helperclass`, Component: <HelperClass /> },
  // { path: `/base/tagsandpills`, Component: <TagsandPills /> },
  // { path: `/base/progressBar`, Component: <ProgressBar /> },
  // { path: `/base/modalComponent`, Component: <ModalComponent /> },
  // { path: `/base/alert`, Component: <AlertComponent /> },
  // { path: `/base/popoverComponent`, Component: <PopoverComponent /> },
  // { path: `/base/tooltipsComponent`, Component: <TooltipsComponent /> },
  // { path: `/base/spinner`, Component: <Spinner /> },
  // { path: `/base/dropdownComponent`, Component: <DropdownComponent /> },
  // { path: `/base/tabs/tab-bootstrap`, Component: <TabBootstrap /> },
  // { path: `/base/tabs/tab-line`, Component: <TabLine /> },
  // { path: `/base/accordion`, Component: <Accordion /> },
  // { path: `/base/navs`, Component: <Navs /> },
  // { path: `/base/shadow`, Component: <Shadow /> },
  // { path: `/base/list`, Component: <List /> },

  // // {/* Advance */}2
  // { path: `/advance/scrollable`, Component: <Scrollable /> },
  // { path: `/advance/bootstrap-notify`, Component: <Toastr /> },
  // { path: `/advance/ratingComponent`, Component: <RatingComponent /> },
  // { path: `/advance/dropzone`, Component: <DropzoneComponent /> },
  // { path: `/advance/tourComponent`, Component: <TourComponent /> },
  // { path: `/advance/sweetAlert`, Component: <SweetAlert /> },
  // { path: `/advance/slick-slider`, Component: <SlickSlider /> },
  // { path: `/advance/carouselComponent`, Component: <CarouselComponent /> },
  // { path: `/advance/ribbon`, Component: <Ribbon /> },
  // { path: `/advance/pagination`, Component: <Pagination /> },
  // { path: `/advance/steps`, Component: <Steps /> },
  // { path: `/advance/uibreadcrumb`, Component: <UIBreadCrumb /> },
  // { path: `/advance/rangeSlider`, Component: <RangeSlider /> },
  // { path: `/advance/imageCropper`, Component: <ImageCropper /> },
  // { path: `/advance/stickyNotes`, Component: <StickyNotes /> },
  // { path: `/advance/dragNDropComp`, Component: <DragNDropComp /> },
  // { path: `/advance/uploadImage`, Component: <UploadImage /> },

  // //   {/* icons */}
  // { path: `/icons/flagIcons`, Component: <FlagIcons /> },
  // { path: `/icons/fontAwsomeIcon`, Component: <FontAwsomeIcon /> },
  // { path: `/icons/icoIcons`, Component: <IcoIcons /> },
  // { path: `/icons/themifyIcons`, Component: <ThemifyIcons /> },
  // { path: `/icons/featherIcons`, Component: <FeatherIcons /> },
  // { path: `/icons/weatherIcons`, Component: <WeatherIcons /> },

  // // {/* buttons */}
  // { path: `/buttons/default-btn`, Component: <DefaultBtn /> },
  // { path: `/buttons/flatBtn`, Component: <FlatBtn /> },
  // { path: `/buttons/edgeBtn`, Component: <EdgeBtn /> },
  // { path: `/buttons/raisedBtn`, Component: <RaisedBtn /> },
  // { path: `/buttons/groupBtn`, Component: <GroupBtn /> },

  // // {/* gallery */}
  // { path: `/gallery/imageGallery`, Component: <ImageGallery /> },
  // { path: `/gallery/imageWithDesc`, Component: <ImageWithDesc /> },
  // { path: `/gallery/imageHover`, Component: <ImageHover /> },
  // { path: `/gallery/mesonryGallery`, Component: <MesonryGallery /> },
  // { path: `/gallery/mesonryDesc`, Component: <MesonryDesc /> },

  // // {/* Forms */}
  // { path: `/forms-controls/form-validation`, Component: <FormValidation /> },
  // { path: `/forms-controls/baseInput`, Component: <BaseInput /> },
  // { path: `/forms-controls/radio-checkbox`, Component: <RadioCheckbox /> },
  // { path: `/forms-controls/inputGroup`, Component: <InputGroupComp /> },
  // { path: `/forms-controls/megaOptions`, Component: <MegaOptions /> },

  // { path: `/form-layout/formDefault`, Component: <FormDefault /> },
  // { path: `/form-layout/FormWizard`, Component: <FormWizard /> },

  // { path: `/form-widget/datepickerComponent`, Component: <DatepickerComponent /> },
  // { path: `/form-widget/typeahead`, Component: <TypeaheadComp /> },

  // // {/* Tables */}
  // { path: `/table/datatable`, Component: <DataTableComponent /> },
  // { path: `/table/basic`, Component: <BasicTable /> },
  // { path: `/table/sizing`, Component: <SizingTable /> },
  // { path: `/table/border`, Component: <BorderTable /> },
  // { path: `/table/styling`, Component: <StylingTable /> },

  // // {/* cards */}
  // { path: `/cards/basicCards`, Component: <BasicCards /> },
  // { path: `/cards/creativeCards`, Component: <CreativeCards /> },
  // { path: `/cards/tabCard`, Component: <TabCard /> },

  // // {/* Timeline */}
  // { path: `/timelines/timeline`, Component: <Timeline /> },
  // { path: `/timelines/timeline2`, Component: <Timeline2 /> },

  // // {/* Charts */}
  // { path: `/charts/googleChart`, Component: <GoogleChart /> },
  // { path: `/charts/chartJs`, Component: <ChartJs /> },
  // { path: `/charts/apex-chart`, Component: <ApexCharts /> },

  // // {/* Map */}
  // { path: `/map/googleMap`, Component: <GoogleMap /> },
  // { path: `/map/leafletMap`, Component: <LeafletMapComp /> },

  // // {/* Editor */}
  // { path: `/editor/ckEditor`, Component: <Editor1 /> },
  // { path: `/editor/mdeEditor`, Component: <Editor2 /> },
  // { path: `/editor/acecodeEditor`, Component: <Editor3 /> },

  // // {/* Users */}
  // { path: `/users/userProfile`, Component: <UserProfile /> },
  // { path: `/users/userEdit`, Component: <UserEdit /> },
  // { path: `/users/userCards`, Component: <UserCards /> },

  // // {/* Calender */}
  // { path: `/calender/calender1`, Component: <Calender1 /> },
  // { path: `/calender/calender2`, Component: <Calender2 /> },

  // // {/* Blog */}
  // { path: `/blog/blogDetail`, Component: <BlogDetail /> },
  // { path: `/blog/blogSingle`, Component: <BlogSingle /> },
  // { path: `/blog/blogPost`, Component: <BlogPost /> },

  // // {/* Social App */}
  // { path: `/social/socialApp`, Component: <SocialApp /> },

  // // {/* Job Search App */}
  // { path: `/jobSearch/cardView`, Component: <CardView /> },
  // { path: `/jobSearch/job-list`, Component: <JobList /> },
  // { path: `/jobSearch/job-detail/:id`, Component: <JobDetail /> },
  // { path: `/jobSearch/job-apply/:id`, Component: <JobApply /> },

  // // {/* Learning App */}
  // { path: `/learning/learning-list`, Component: <LearningList /> },
  // { path: `/learning/learning-detail/:id`, Component: <LearningDeatil /> },

  // // {/* FAQ */}
  // { path: `/faq/faqComponent`, Component: <FaqComponent /> },

  // // {/* Knowledgebase */}
  // { path: `/knowledgebase/knowledgebaseComponent`, Component: <KnowledgebaseComponent /> },

  // // {/* Support Ticket */}
  // { path: `/support-ticket/supportTicket`, Component: <SupportTicket /> },

  // // {/* Applications */}
  // { path: `/todo-app/todo`, Component: <Todo /> },
  // { path: `/email-app/emailDefault`, Component: <EmailDefault /> },
  // { path: `/chat-app/chat`, Component: <Chat /> },

  // // {/* Ecommerce App */}
  // { path: `/ecommerce/product`, Component: <EcommerceApp /> },
  // { path: `/ecommerce/cart/:id`, Component: <AddToCart /> },
  // { path: `/ecommerce/wishlist/:id`, Component: <WishlistComponent /> },
  // { path: `/ecommerce/product-detail/:id`, Component: <ProductDetail /> },
  // { path: `/ecommerce/checkout`, Component: <Checkout /> },
  // { path: `/ecommerce/invoice`, Component: <Invoice /> },
  // { path: `/ecommerce/product-list`, Component: <ProductList /> },
  // { path: `/ecommerce/payment`, Component: <Payment /> },
  // { path: `/ecommerce/history`, Component: <History /> },

  // // {/* CONTACT APP */}
  // { path: `/contact-app/contact`, Component: <ContactApp /> },

  // // {/* Bookmark App */}
  // { path: `/bookmark-app/bookmark`, Component: <BookmarkApp /> },

  // // {/* Task App */}
  // { path: `/taks-app/task`, Component: <TaskApp /> },

  // { path: `/project/project-list`, Component: <ProjectApp /> },
  // { path: `/project/new-project`, Component: <NewProject /> },
  // { path: `/project/project-details`, Component: <ProjectDetails /> },
  // { path: `/file-manager`, Component: <FileManager /> },

  // // {/* Search page */}
  // { path: `/search/searchpage`, Component: <Searchpage /> },

  // // {/* Sample page */}
  // { path: `/sample/samplepage`, Component: <Samplepage /> },

  // // {/* Pricing */}
  // { path: `/price/pricing`, Component: <Pricing /> },
];
