const LoaderOverlay = () => {
    
    return (
      <div className="relative">
    
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-35 ">
            <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-[#1EC2E5]"></div>
          </div>
    
      </div>
    );
  };

export default LoaderOverlay;