import React from 'react'

export const alertDetail =[
    {
        id : 1,
        time:"Mar 1, 7:18 PM",
        sentTo: "Chris@airify.com",
        details:  "PM 2.5 @ STARBUCKS PORT JEFF FRONT OF HOUSE Device 401000271" ,
     
    },
    {
        id : 2,
        time:"Mar 1, 4:05 PM",
        sentTo: "Chris@airify.com",
        details:  "Mar 1, 4:05 PMRelative Humidity @ STARBUCKS PATCHOGUE FRONT OF HOUSE Device 4010002811" ,
     
    },
    {
        id : 3,
        time:"Mar 1, 4:02 PM",
        sentTo: "Chris@airify.com",
        details:  "Relative Humidity @ STARBUCKS PATCHOGUE BACK OF HOUSE Device 401000293" ,
     
    },
    {
        id : 4,
        time:"Mar 1, 1:59 AM",
        sentTo: "Chris@airify.com",
        details:  "Relative Humidity @ STARBUCKS PATCHOGUE FRONT OF HOUSE Device 401000281" ,
     
    },
    {
        id : 5,
        time:"Mar 1, 7:18 PM",
        sentTo: "Chris@airify.com",
        details:  "PM 2.5 @ STARBUCKS PORT JEFF FRONT OF HOUSE Device 401000271" ,
     
    },
    {
        id : 6,
        time:"Mar 1, 7:18 PM",
        sentTo: "Chris@airify.com",
        details:  "PM 2.5 @ STARBUCKS PORT JEFF FRONT OF HOUSE Device 401000271" ,
     
    },
    {
        id : 7,
        time:"Mar 1, 7:18 PM",
        sentTo: "Chris@airify.com",
        details:  "PM 2.5 @ STARBUCKS PORT JEFF FRONT OF HOUSE Device 401000271" ,
     
    },
    {
        id : 8,
        time:"Mar 1, 7:18 PM",
        sentTo: "Chris@airify.com",
        details:  "PM 2.5 @ STARBUCKS PORT JEFF FRONT OF HOUSE Device 401000271" ,
     
    },
   
  
]
export const tableData =[
    {
        space:"Room 1",
        occupancy: "10%",
        floor:  1 ,
        type:"Need Attention",
        progress  : 10
    },
    {
        space:"Room 2",
        occupancy: "100%",
        floor: 2 ,
        type:"Excellent",
        progress  : 100 
    },
    {
        space:"Room 3",
        occupancy: "70%",
        floor: 3 ,
        type:"Inactive",
        progress  : 50 
    },
    {
        space:"Room 4",
        occupancy: "80%",
        floor: 4,
        type:"Good",
        progress  : 80
    },
    {
        space:"Room 5",
        occupancy: "100%",
        floor: 5 ,
        type:"Open Plan",
        progress  : 100
    },
  
]


export const DummyData = {
    "pm25": {
        "colorArray": [
            "#007300",
            "#E69500",
            "#E60000"
        ],
        "data": [
            {
                "limit": 0.4,
                "tooltip": {
                    "text": "0-0.4",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            },
            {
                "limit": 0.4,
                "tooltip": {
                    "text": "0.4-0.4",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            },
            {
                "limit": 0.4,
                "tooltip": {
                    "text": "0.4-100",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            }
        ],
        "value": 0.3,
        "legendaArray": [
            {
                "text": "0-0.4",
                "color": "#007300"
            },
            {
                "text": "0.4-0.4",
                "color": "#E69500"
            },
            {
                "text": "0.4-100",
                "color": "#E60000"
            }
        ],
        "lowValue": 0.4,
        "highValue": 0.4,
        "readings": 0.4000000000000019,
        "mins": "30 mins",
        "counter": 288
    },
    "co": {
        "colorArray": [
            "#007300",
            "#E69500",
            "#E60000"
        ],
        "data": [
            {
                "limit": 0.617,
                "tooltip": {
                    "text": "0-0.617",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            },
            {
                "limit": 10.617,
                "tooltip": {
                    "text": "0.617-0.617",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            },
            {
                "limit": 100,
                "tooltip": {
                    "text": "0.617-100",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            }
        ],
        "value": 0.6169999999999985,
        "legendaArray": [
            {
                "text": "0-0.617",
                "color": "#007300"
            },
            {
                "text": "0.617-0.617",
                "color": "#E69500"
            },
            {
                "text": "0.617-100",
                "color": "#E60000"
            }
        ],
        "lowValue": 0.617,
        "highValue": 0.617,
        "readings": 0.6169999999999985,
        "mins": "30 mins",
        "counter": 288
    },
    "co2": {
        "colorArray": [
            "#007300",
            "#E69500",
            "#E60000"
        ],
        "data": [
            {
                "limit": 664,
                "tooltip": {
                    "text": "0-664",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            },
            {
                "limit": 674,
                "tooltip": {
                    "text": "664-664",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            },
            {
                "limit": 684,
                "tooltip": {
                    "text": "664-100",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            }
        ],
        "value": 664,
        "legendaArray": [
            {
                "text": "0-664",
                "color": "#007300"
            },
            {
                "text": "664-664",
                "color": "#E69500"
            },
            {
                "text": "664-100",
                "color": "#E60000"
            }
        ],
        "lowValue": 664,
        "highValue": 664,
        "readings": 664,
        "mins": "30 mins",
        "counter": 288
    },
    "temp": {
        "colorArray": [
            "#007300",
            "#E69500",
            "#E60000"
        ],
        "data": [
            {
                "limit": 74.05,
                "tooltip": {
                    "text": "0-74.05",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            },
            {
                "limit": 84.05,
                "tooltip": {
                    "text": "74.05-74.05",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            },
            {
                "limit": 100,
                "tooltip": {
                    "text": "74.05-100",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            }
        ],
        "value": 0.6571180555555576,
        "legendaArray": [
            {
                "text": "0-74.05",
                "color": "#007300"
            },
            {
                "text": "74.05-74.05",
                "color": "#E69500"
            },
            {
                "text": "74.05-100",
                "color": "#E60000"
            }
        ],
        "lowValue": 74.05,
        "highValue": 74.05,
        "readings": 0.6571180555555576,
        "mins": "30 mins",
        "counter": 288
    },
    "chem": {
        "colorArray": [
            "#007300",
            "#E69500",
            "#E60000"
        ],
        "data": [
            {
                "limit": 60.1585,
                "tooltip": {
                    "text": "0-60.1585",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            },
            {
                "limit": 70.1585,
                "tooltip": {
                    "text": "60.1585-60.1585",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            },
            {
                "limit": 100,
                "tooltip": {
                    "text": "60.1585-100",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            }
        ],
        "value": 0.6187500000000019,
        "legendaArray": [
            {
                "text": "0-60.1585",
                "color": "#007300"
            },
            {
                "text": "60.1585-60.1585",
                "color": "#E69500"
            },
            {
                "text": "60.1585-100",
                "color": "#E60000"
            }
        ],
        "lowValue": 60.1585,
        "highValue": 60.1585,
        "readings": 0.6187500000000019,
        "mins": "30 mins",
        "counter": 288
    },
    "humidity": {
        "colorArray": [
            "#007300",
            "#E69500",
            "#E60000"
        ],
        "data": [
            {
                "limit": 47.67,
                "tooltip": {
                    "text": "0-47.67",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            },
            {
                "limit": 57.67,
                "tooltip": {
                    "text": "47.67-47.67",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            },
            {
                "limit": 100,
                "tooltip": {
                    "text": "47.67-100",
                    "style": {
                        "backgroundColor": "#555"
                    }
                }
            }
        ],
        "value": 0.5038350694444463,
        "legendaArray": [
            {
                "text": "0-47.67",
                "color": "#007300"
            },
            {
                "text": "47.67-47.67",
                "color": "#E69500"
            },
            {
                "text": "47.67-100",
                "color": "#E60000"
            }
        ],
        "lowValue": 47.67,
        "highValue": 47.67,
        "readings": 0.5038350694444463,
        "mins": "30 mins",
        "counter": 288
    }
}