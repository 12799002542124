import { MdOutlineCircle } from "react-icons/md";
import {
  Acceptable,
  ActionRecommended,
  ActionNecessary,
  StateNotActive,
} from "../../constant";

export const renderAirQualityIcon = (status) => (
  <MdOutlineCircle
    size={30}
    color={
      status === Acceptable.text
        ? Acceptable.color
        : status === ActionRecommended.text
        ? ActionRecommended.color
        : status === null
        ? StateNotActive.color
        : ActionNecessary.color
    }
  />
);
