import { HttpService } from "../../services/httpService";
import { fetchDeviceFailure, fetchDeviceStart, fetchDeviceSuccess } from "./deviceListSlice";

export const fetchDeviceListData = () => async (dispatch) => {
    try {
      dispatch(fetchDeviceStart());
      const response = await HttpService.CoreApiGet('assets/list'); 
      dispatch(fetchDeviceSuccess(response?.data?.body));
    } catch (error) {
      dispatch(fetchDeviceFailure(String(error))); 
    }
};
