import React from "react";


const Dropdowns = (props) => {
  return (
    <select
      onChange={(val) => props.onChange(val.target.value)}
      className={`bg-[#f4f7fedd] rounded-md p-2 px-5 text-normal ${props?.width}`}
    >
      <option disabled selected>
        {props?.title}
      </option>
      {props?.data?.map((e, index) => {
        return (
          <option value={e} key={index}>
            {e}
          </option>
        );
      })}
    </select>
  );
};

export default Dropdowns;
