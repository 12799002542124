export class LocalStorageService {
    static Set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static Get(key) {
        var item = localStorage.getItem(key);
        if (!item)
            return null;

        return JSON.parse(item);
    }
}
