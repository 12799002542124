import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const deviceSlice = createSlice({
  name: "devicelist",
  initialState,
  reducers: {
    fetchDeviceStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchDeviceSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
    },
    fetchDeviceFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchDeviceStart, fetchDeviceSuccess, fetchDeviceFailure } = deviceSlice.actions;

export default deviceSlice.reducer;
