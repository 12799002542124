import { MdOutlineCircle } from "react-icons/md";

function AirQualityIcon({ score, color }) {
  return (
    <div className="flex gap-1 items-center">
      <p>
        <MdOutlineCircle color={`${color}`} size={30} />
      </p>
      <p style={{ color: {color} }}>{score}</p>
    </div>
  );
}

export default AirQualityIcon;
