import { jwtDecode } from "jwt-decode";
import { updateToken } from "../slice/authSlice";
import { store } from "../store";
import { LocalStorageService } from "./localStorageService";

export class UserService {
    static SaveAccessToken(access_token) {
        LocalStorageService.Set("token", access_token);
        store.dispatch(updateToken(access_token));
    }

    static GetAccessToken() {
        let access_token_state = store.getState().authReducer.value.token;
        if (access_token_state) {
            return access_token_state;
        }

        let access_token_storage = LocalStorageService.Get("token");
        if (access_token_storage) {
            store.dispatch(updateToken(access_token_storage));
            LocalStorageService.Set("userDetail", jwtDecode(access_token_storage));
        }

        return access_token_storage;
    }
}

export default UserService;
