import React, { useEffect, Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import "react-time-picker/dist/TimePicker.css";
import DeviceList from "../deviceList/deviceList";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocationData,
} from "../../../redux/location/locationAction";
import LoaderOverlay from "../../loader/loader";
import { HttpService } from "../../../services/httpService";
import { toast } from "react-toastify";
import {
  Acceptable,
  ActionRecommended,
  ActionNecessary,
  StateNotActive,
} from "../../../constant";
import AirQualityIcon from "../../common/AirQualityIcon";

const Default = () => {
  const [deviceList, setDeviceList] = useState([]);
  const [keyMetrics, setKeyMetrics] = useState([]);
  const dispatch = useDispatch();
  const locationData = useSelector((state) => state?.location);
  const [locationId, setLocationId] = useState("");

  useEffect(() => {
    dispatch(fetchLocationData());
  }, [dispatch]);


  useEffect(() => {
    if (locationData?.data?.length > 0) {
      setLocationId([locationData.data[0].id]);
    }
  }, [locationData]);

  const handleFilter = async (locationId) => {
    setLocationId(locationId);
  };

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const response = await HttpService.CoreApiPost("assets/dashboard", {
          filters: {
            locations: locationId,
          },
        });
        setDeviceList(response?.data?.body?.deviceList);
        setKeyMetrics(response?.data?.body?.key_metrics);
      } catch (error) {
        console.error(error);
        toast.error(error?.response?.data?.message || "Failed to apply filter");
      }
    };

    if (locationId) {
      fetchDashboard();
    }
  }, [locationId]);

  return (
    <>
      {locationData?.loading ? (
        <LoaderOverlay />
      ) : (
        <Fragment>
          <Breadcrumb parent="" title="" />
          <div className="container-fluid">
            <div className="dashboard">
              <div className="flex flex-col items-center lg:items-start bg-[#fff]  p-4">
                <div className="flex flex-col md:flex-row justify-between items-center w-full">
                  <div className="flex flex-col md:flex-row  gap-3  items-center">
                    <div className="flex gap-2 items-center">
                      <p className="text-xl font-semibold">Location:</p>
                      <div className=" md:mt-0 min-w-[250px]">
                        <select
                          onChange={(e) => handleFilter([e?.target?.value])}
                          className={`bg-[#f4f7fedd] rounded-full p-2 px-5 text-lg `}
                        >
                          {locationData?.data?.map((e, index) => {
                            return (
                              <option value={e?.id} key={index}>
                                {`${e?.name}` || "Not Found"}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card p-4 mt-3 rounded-md ">
                <div className="block md:flex flex-wrap justify-between  ">
                  <p className="text-2xl font-semibold text-black">
                    Key Metrics
                  </p>
                </div>

                <div className="cards mt-3 md:mt-0 flex-col md:flex-row overflow-y-hidden items-center max-h-48 p-1">
                  <Card
                    text1={keyMetrics?.pm25?.item_name}
                    acceptable={keyMetrics?.pm25?.acceptable}
                    actionRecommended={keyMetrics?.pm25?.actionRecommended}
                    actionNecessary={keyMetrics?.pm25?.actionNecessary}
                    inactive={keyMetrics?.pm25?.inactive}
                  />
                  <Card
                    text1={keyMetrics?.co2?.item_name}
                    actionNecessary={keyMetrics?.co2?.actionNecessary}
                    acceptable={keyMetrics?.co2?.acceptable}
                    actionRecommended={keyMetrics?.co2?.actionRecommended}
                    inactive={keyMetrics?.co2?.inactive}
                  />
                  <Card
                    text1={keyMetrics?.co?.item_name}
                    actionNecessary={keyMetrics?.co?.actionNecessary}
                    acceptable={keyMetrics?.co?.acceptable}
                    actionRecommended={keyMetrics?.co?.actionRecommended}
                    inactive={keyMetrics?.co?.inactive}
                  />
                  <Card
                    text1={keyMetrics?.tvoc?.item_name}
                    actionNecessary={keyMetrics?.tvoc?.actionNecessary}
                    acceptable={keyMetrics?.tvoc?.acceptable}
                    actionRecommended={keyMetrics?.tvoc?.actionRecommended}
                    inactive={keyMetrics?.tvoc?.inactive}
                  />
                  <Card
                    text1={"°F"}
                    actionNecessary={keyMetrics?.temperature?.actionNecessary}
                    acceptable={keyMetrics?.temperature?.acceptable}
                    actionRecommended={
                      keyMetrics?.temperature?.actionRecommended
                    }
                    inactive={keyMetrics?.temperature?.inactive}
                  />
                  <Card
                    text1={"RH"}
                    actionNecessary={keyMetrics?.humidity?.actionNecessary}
                    acceptable={keyMetrics?.humidity?.acceptable}
                    actionRecommended={keyMetrics?.humidity?.actionRecommended}
                    inactive={keyMetrics?.humidity?.inactive}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" p-4 bg-[#fff] rounded-md ">
            <div className="-mt-10">
              <DeviceList data={{ data: deviceList }} />
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

const Card = ({
  text1,
  acceptable,
  actionNecessary,
  inactive,
  actionRecommended,
}) => {
  return (
    <div className="card metric w-[175px]">
      <div>
        <p className="text-lg">{text1}</p>
        <AirQualityIcon score={acceptable} color={Acceptable.color} />
        <AirQualityIcon
          score={actionRecommended}
          color={ActionRecommended.color}
        />
        <AirQualityIcon score={actionNecessary} color={ActionNecessary.color} />
        <AirQualityIcon score={inactive} color={StateNotActive.color} />
      </div>
    </div>
  );
};

export default Default;
