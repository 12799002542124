import { alertDetail, tableData } from "../../../data/dummyTableData";
import Breadcrumb from "../../common/breadcrumb";
import DataTableComponent from "../../tables/dataTableComponent";
import { AiOutlineMore } from "react-icons/ai";


const AlertDetail = ()=>{
    const columns = [
        {
          name: 'Alert Time',
          selector: (row) => row?.time,
          sortable: true,
          center: true,
        },
        {
          name: 'Sent To',
          selector: (row) => row?.sentTo,
          sortable: true,
          center: true,
        },
        {
          name: 'Alert Detail',
          cell : (row)=>(
            <div>
          {row?.details}
        

         </div>
        ),
          sortable: true,
          center: true,
        },
        {
            name : "Action",
            sortable: true,
            center: true,
            cell : (row)=>(
                <div>
               <AiOutlineMore fontSize={"large"}/>

             </div>
            )
        }
       
    
        
    
      ];
    return(
        <div>
             <Breadcrumb parent="" title="" />
             <div className="mt-3">
                <DataTableComponent tableData={alertDetail} columns={columns} />
              </div>
        </div>
    )
}
export default AlertDetail;