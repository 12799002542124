import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "../slice/authSlice"
import deviceListSlice from "../redux/deviceList/deviceListSlice";
import deviceDetailSlice from "../redux/deviceDetail/deviceDetailSlice";
import settingSlice from "../redux/settings/settingSlice";
import locationSlice from "../redux/location/locationSlice";


const rootPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["userDetails"],
};

const userReducerConfig = {
  key: "userReducer",
  storage: storage,
};

const rootReducer = combineReducers({
  authReducer: authSlice ,
  deviceList : deviceListSlice,
  deviceDetail : deviceDetailSlice, 
  location : locationSlice,
  setting : settingSlice,
 
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);