import { useEffect, useState } from "react";
import { Breadcrumb, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchDates,
    fetchLocationData,
} from "../../../redux/location/locationAction";
import ReactDatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import "react-time-picker/dist/TimePicker.css";
import DropdownSelect from "../../base/DropDown/Dropdown";
import LineChart from "../../charts/lineChart";
import { HttpService } from "../../../services/httpService";
import { fetchDeviceListData } from "../../../redux/deviceList/deviceListAction";
import LoaderOverlay from "../../loader/loader";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import moment from "moment";

const ReportFilter = () => {
    const state = useSelector((state) => state?.location);
    const locations = useSelector((state) => state?.deviceList);

    const [filterDates, setFilterDate] = useState()
    const [interval, setInterval] = useState("");
    const [location, setLocation] = useState([]);
    const [graphData, setGraphData] = useState(null);
    const [airName, setAirName] = useState("pm25");
    const [dates, setDates] = useState([]);
    const [date, setDate] = useState("");
    const [loader, setLoader] = useState(true);
    const [fetchsData, setFetchData] = useState({});
    const [initialLoad, setInitialLoad] = useState(false)
    const dispatch = useDispatch();
    const { data, loading, error } = locations;
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        dispatch(fetchLocationData());
        dispatch(fetchDeviceListData());
        onload();
    }, []);
    const windowHeight = window.innerHeight;
    // Setting the initial state to the previous day's date
    const getPreviousDate = () => {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        return today;
    };

    // State for the selected date initialized to the previous day's date
    const [selectedDate, setSelectedDate] = useState(getPreviousDate());
  
    const options = locations?.data
        ?.filter((elem) => elem.locations)
        .map((elem) => ({
            name: elem?.locations?.name + " , " + elem?.device_id,
            id: elem.id,
        }));
    useEffect(() => {
        if (options.length > 0 && !location.length) {
            setLocation([options[0]])
        }
    }, [options.length])

    const onload = async () => {
        const data = await fetchDates();
        setDates(data);
    };

    const filterDate = (dateString) => {

        const formattedDate = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
        setFilterDate(formattedDate)
        return formattedDate
    }

    useEffect(() => {
        filterDate(selectedDate)
    }, [selectedDate])

    const handleFilter = async () => {
        if (locations && locations.data && locations.data.length > 0) {
            setIsLoading(true); // Set loading state to true

            const locationId = locations.data[0].id;
            try {
                const response = await HttpService.CoreApiPost(`assets/sensors`, {
                    filters: {
                        locations: location?.length ? location?.map((elem) => elem.id) : [locationId],
                        until_date: filterDates,
                        prior_period: date ? date : "1 day",
                        interval: interval ? interval : "30 min",
                        readings: {
                            pm25: true,
                            co: true,
                            co2: true,
                            temperature: true,
                            humidity: true,
                            tvoc: true,
                        },
                    },
                });
                setFetchData(response?.data?.body);
                toast.success("Filter applied successfully");
            } catch (error) {
                console.error("Error applying filter:", error);
              
                toast.error(error?.response?.data?.message || "Failed to apply filter")
            } finally {
                setIsLoading(false); // Set loading state back to false
            }
        } else {
            console.log("No location found");
        }
    };

    const handleLocation = (e) => {
        const id = e.target.value;
        const index = location.findIndex((elem) => elem == id);

        if (index === -1) {
            setLocation([...location, Number(id)]);
        } else {
            let locationArr = [...location];
            locationArr.splice(index, 1);
            setLocation(locationArr);
        }
    };

    const intervalArr = ["5 min", "10 min", "15 min", "60 min"];
    useEffect(() => {
        if (!initialLoad) {
            setGraphData(null);
            setLoader(true); // Set loading to true when fetching data starts
            const fetchData = async () => {
                try {
                    // Assuming locations data is available and it's an array
                    if (locations && locations?.data && locations?.data?.length > 0) {
                        const locationId = locations.data[0].id; // Assuming location id is directly accessible

                        const response = await HttpService.CoreApiPost(`assets/sensors`, {
                            filters: {
                                locations: location?.length ? location?.map((elem) => elem.id) : [locationId],
                                until_date: filterDates ? filterDates : null,
                                prior_period: date ? date : "1 day",
                                interval: interval ? interval : "30 min",
                                readings: {
                                    pm25: true,
                                    co: true,
                                    co2: true,
                                    temperature: true,
                                    humidity: true,
                                    tvoc: true,
                                },
                            },
                        });

                        setFetchData(response?.data?.body)
                        setInitialLoad(true)
                        // Assuming response data structure is { body: { pm25: [], label: '' } }
                        setGraphData({
                            data: response?.data?.body?.[airName] || [],
                            label: response?.data?.body?.label || "",
                        });
                    } else {
                        console.log("No locations found.");
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                    toast.error(error?.response?.data?.message || "Failed to apply filter")
                    // Handle error gracefully, such as showing a message to the user
                } finally {
                    setLoader(false); // Set loading to false when fetching is done (either success or error)
                }
            };

            fetchData();
        }
    }, [locations, airName, initialLoad, filterDates]);


    useEffect(() => {
        if (initialLoad) {
            // No need to access fetchData here
            setGraphData({
                data: fetchsData?.[airName] || [], // Access graphData directly
                label: fetchsData?.label || "",
            });
        }
    }, [airName, fetchsData, initialLoad]);
    function onSelect(selectedList, selectedItem) {
        setLocation(selectedList);
    }

    function onRemove(selectedList, removedItem) {
        setLocation(selectedList);
    }

    return (
        <>
            {loading ? (
                <LoaderOverlay />
            ) : (
                <>
                    <Breadcrumb parent="" title="" />
                    <div className="bg-[#fff] p-5 rounded-md">
                    <div className="flex gap-2 items-center">
                                <p className="text-xl font-semibold">Device:</p>
                                <Multiselect
                                className="mt-3"
                                options={options}
                                onSelect={onSelect}
                                onRemove={onRemove}
                                displayValue="name"
                                selectedValues={location}
                            />
                        </div>

                        <div className="flex flex-col lg:flex-row lg:justify-start gap-3 lg:items-center mt-2">
                        <div className="flex gap-2 items-center">
                                <p className="text-xl font-semibold">Look back over:</p>
                                <DropdownSelect
                                    onChange={setDate}
                                    data={dates}
                                    title="1 day"
                                />
                            </div>
                            <div className="flex mt-2 lg:mt-0  items-center gap-2">
                                <p className="text-xl font-semibold">Until:</p>
                                <div className="input-group max-w-64 flex gap-4 bg-[#F4F7FE] p-2 px-4 rounded-2xl cursor-pointer ">
                                    <div
                                        onClick={() =>
                                            document
                                                .querySelector(
                                                    ".react-datepicker-wrapper > .react-datepicker__input-container > input"
                                                )
                                                .click()
                                        }
                                        className=""
                                    >
                                        <FaCalendarAlt className="text-[#A5B4CB] text-lg" />
                                    </div>
                                    <ReactDatePicker
                                        className="bg-transparent "
                                        selected={selectedDate}
                                        onChange={(date) => {
                                            filterDate(date)
                                            setSelectedDate(date)
                                        }}
                                        dateFormat="MM/dd/yyyy" // Customize date format if needed
                                    />
                                </div>
                            </div>
                            <div className="flex   items-center gap-2">
                                <p className="text-xl font-semibold">Interval:</p>
                                <DropdownSelect
                                    onChange={setInterval}
                                    data={intervalArr}
                                    title="30 min"
                                />
                            </div>
                        </div>
                        <button
                            className="bg-[green] text-[#fff] p-2 px-5 rounded-md mt-3"
                            onClick={handleFilter}
                            disabled={isLoading}
                        >
                            {isLoading ? <p className="text-white">Loading...</p> : <p className="text-white">Apply Filter</p>}
                        </button>
                    </div>
                    <div className="mt-3 border p-5 bg-white ">
                        <div className="grid grid grid-cols-1 md:grid-cols-4  lg:grid-cols-6 gap-2 m-4">
                            <button
                                onClick={() => setAirName("pm25")}
                                className={`cursor-pointer p-2 rounded-md ${airName === "pm25"
                                    ? "bg-[#1EC2E5] text-[#fff]"
                                    : "bg-[#F4F7FE] text-[#A3AED0]"
                                    }`}
                            >
                                Particulates (PM 2.5)
                            </button>
                            <button
                                onClick={() => setAirName("co2")}
                                className={`cursor-pointer  p-2 rounded-md ${airName === "co2"
                                    ? "bg-[#1EC2E5] text-[#fff]"
                                    : "bg-[#F4F7FE] text-[#A3AED0]"
                                    }`}
                            >
                                Carbon Dioxide (CO2)
                            </button>
                            <button
                                onClick={() => setAirName("co")}
                                className={`cursor-pointer p-2 rounded-md ${airName === "co"
                                    ? "bg-[#1EC2E5] text-[#fff]"
                                    : "bg-[#F4F7FE] text-[#A3AED0]"
                                    }`}
                            >
                                Carbon Monoxide (CO)
                            </button>
                            <button
                                onClick={() => setAirName("tvoc")}
                                className={`cursor-pointer  p-2 rounded-md ${airName === "tvoc"
                                    ? "bg-[#1EC2E5] text-[#fff]"
                                    : "bg-[#F4F7FE] text-[#A3AED0]"
                                    }`}
                            >
                                Chemicals (TVOC)
                            </button>
                            <button
                                onClick={() => setAirName("humidity")}
                                className={`cursor-pointer p-2 rounded-md ${airName === "humidity"
                                    ? "bg-[#1EC2E5] text-[#fff]"
                                    : "bg-[#F4F7FE] text-[#A3AED0]"
                                    }`}
                            >
                                Humidity (RH)
                            </button>
                            <button
                                onClick={() => setAirName("temperature")}
                                className={`cursor-pointer p-2 rounded-md ${airName === "temperature"
                                    ? "bg-[#1EC2E5] text-[#fff]"
                                    : "bg-[#F4F7FE] text-[#A3AED0]"
                                    }`}
                            >
                                Temperature (°F)
                            </button>
                        </div>
                        {loader ? <div className="flex justify-center p-10">
                            <Spinner size="large" variant="primary" />
                        </div> :
                        
                            graphData && <div className="min-w-[250px] min-h-[350px]">
                            <LineChart json={graphData} />
                        </div>}
                    </div>
                </>
            )}
        </>
    );
};
export default ReportFilter;
