import Breadcrumb from "../../common/breadcrumb";
import DataTableComponent from "../../tables/dataTableComponent";
import { AiOutlineMore } from "react-icons/ai";
import { useEffect, useState } from "react";
//import { fetchDeviceListData } from "../../../redux/deviceList/deviceListAction";
import { useNavigate } from "react-router-dom";
import LoaderOverlay from "../../loader/loader";
import { Acceptable } from "../../../constant";
import { renderAirQualityIcon } from "../../utils"


const DeviceList = (props) => {
  //const deviceList = useSelector((state) => state?.deviceList);
  const deviceList = props.data;
  const navigate = useNavigate();
  const [exceptionFilter, setExceptionFilter] = useState(false);
  const { data, loading, error } = deviceList;
  const [exceptionFilterData, setExceptionFilterData] = useState([]);
  
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = date.getMonth();
    const month = monthNames[monthIndex];
    const day = date.getDate();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const minutes = date.getMinutes();
    const formattedDate = `${month} ${day}, ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
    return formattedDate;
  }

  const handleRowClick = (row) => {
    // Handle row click event
    // You can navigate to another page here
    navigate(`/devices/detail/${row?.id}`); // Example: Navigate to details page with device_id
  };

  useEffect(() => {
    if (data.length > 0) {
      let temp = [];
      data.forEach((element) => {
        if (
          element.PM_2_5_air_quality !== Acceptable.text ||
          element.co2_air_quality !== Acceptable.text ||
          element.co_air_quality !== Acceptable.text ||
          element.temperature_air_quality !== Acceptable.text ||
          element.humidity_air_quality !== Acceptable.text ||
          element.chemical_air_quality !== Acceptable.text
        ) {
          temp.push(element);
        }
      });
      setExceptionFilterData(temp);
    }
  }, [data]);

  const columns = [
    {
      name: "Device",
      selector: (row) => row?.device_id,
      sortable: true,
      center: false,
      cell: (row) => (
        <div className="cursor-pointer" onClick={() => handleRowClick(row)}>
          {row?.device_id || ""}
        </div>
      ),
    },
    {
      name: "Location",
      selector: (row) => row?.['locations.name'] || '',
      width: "200px",
      sortable: true,
      center: false,
      cell: (row) => (
        <div className="cursor-pointer" onClick={() => handleRowClick(row)}>
          {row?.['locations.name'] || ''}
        </div>
      ),
    },
    {
      name: "Last Connection",
      selector: (row) => row?.last_connection,
      width: "200px",
      cell: (row) => (
        <div className="cursor-pointer" onClick={() => handleRowClick(row)}>
          {formatTimestamp(row?.last_connection)}
        </div>
      ),
      sortable: true,
      center: false,
    },
    {/*
      name: "Alert Setting Override",
      selector: (row) => row?.time,
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="cursor-pointer" onClick={() => handleRowClick(row)}>
          {row?.time || ""}
        </div>
      ),
    */},
    {
      name: "PM 2.5",
      selector: (row) => row?.PM_2_5_air_quality,
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="cursor-pointer" onClick={() => handleRowClick(row)}>
          {renderAirQualityIcon(row?.PM_2_5_air_quality)}
        </div>
      )
    },
    {
      name: "CO2",
      selector: (row) => row?.co2_air_quality,
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="cursor-pointer" onClick={() => handleRowClick(row)}>
          {renderAirQualityIcon(row?.co2_air_quality)}
        </div>
      )
    },
    {
      name: "CO",
      selector: (row) => row?.co_air_quality,
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="cursor-pointer" onClick={() => handleRowClick(row)}>
          {renderAirQualityIcon(row?.co_air_quality)}
        </div>
      )      
    },
    {
      name: "TVOC",
      selector: (row) => row?.chemical_air_quality,
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="cursor-pointer" onClick={() => handleRowClick(row)}>
          {renderAirQualityIcon(row?.chemical_air_quality)}
        </div>
      )      
    },
    {
      name: "°F",
      selector: (row) => row?.temperature_air_quality,
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="cursor-pointer" onClick={() => handleRowClick(row)}>
          {renderAirQualityIcon(row?.temperature_air_quality)}
        </div>
      )      
    },
    {
      name: "RH",
      selector: (row) => row?.humidity_air_quality,
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="cursor-pointer" onClick={() => handleRowClick(row)}>
          {renderAirQualityIcon(row?.humidity_air_quality)}
        </div>
      )      
    },
    {
      name: "Mold",
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="cursor-pointer" onClick={() => handleRowClick(row)}>
          {renderAirQualityIcon(row?.temperature_air_quality)}
        </div>
      )      
    },
    {
      name: "Action",
      sortable: true,
      center: true,
      cell: (row) => (
        <div>
          <AiOutlineMore fontSize={"medium"} />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb parent="" title="" />
      <div className="flex items-center justify-between">
        <p className="text-2xl font-semibold text-black">
          Real Time Air Quality
        </p>
        <div>
          <button
            onClick={() => setExceptionFilter(!exceptionFilter)}
            className={` ${
              exceptionFilter
                ? "bg-[#1ec2e5] text-white"
                : "bg-[#f4f7fedd] text-black"
            } p-1 px-4 rounded-md flex items-center  `}
            type="checkbox"
          >
            <input
              type="checkbox"
              name="exceptionFilter"
              id="exception"
              className="h-4 w-4 mr-2 p-1  accent-[#fff] bg-gray-200 border-2 border-gray-300 rounded-md checked:bg-[#fff] checked:border-transparent checked:text-[#1ec2e5]"
              checked={exceptionFilter}
              onChange={() => {
                // component === "date" ? setComponent("prior-period") : setComponent("date")
              }}
            />
            <label className="mt-2" htmlFor="exception">
              Exceptions Only
            </label>
          </button>
        </div>
      </div>
      <div className="mt-3">
        {loading ? (
          <LoaderOverlay />
        ) : error ? (
          <p>Error: {error.message}</p>
        ) : (
          <DataTableComponent
            pagination={true}
            onRowClicked={handleRowClick}
            tableData={exceptionFilter ? exceptionFilterData : data}
            columns={columns}
          />
        )}
      </div>
    </div>
  );
};
export default DeviceList;
