import { useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb"
import { useDispatch, useSelector } from "react-redux";
import { updateLocation, fetchLocationData } from "../../../redux/location/locationAction";
import LoaderOverlay from "../../loader/loader";
import { Loader } from "react-bootstrap-typeahead";
import { HttpService } from "../../../services/httpService";
import { toast } from "react-toastify";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiTwotoneEdit } from "react-icons/ai";

const Location = () => {
    const [trueInput, setTrueInput] = useState(false);
    const state = useSelector(state => state?.location);
    const [locationName, setLocationName] = useState('');
    const [editLocationItem, seteditLocationItem] = useState(null)
    const [editLocationIndex, seteditLocationIndex] = useState(null)

    const dispatch = useDispatch();
    const { data, loading, error } = state
    useEffect(() => {
        dispatch(fetchLocationData(false))
    }, [])

    const addLocation = async () => {
        const data = [{
            name: locationName
        }]
        const response = await HttpService.CoreApiPost(`location/post`, data).then((success) => {
            dispatch(fetchLocationData(false))
            toast.success("Location added sucessfully")
            setLocationName("")
            setTrueInput(false)
        }).catch((err) => toast.error(err?.response?.data?.message || "Something went wrong"))
    }


    const onCancel = () => {
        setTrueInput(false);
        setLocationName("")
    }

    const onDelete = (item, index) => {
        let temp = [...data]
        temp[index] = { ...temp[index], is_removed: 1 }
        dispatch(updateLocation(temp))
    }

    const onEdit = (item, index) => {
        setTrueInput(false)
        seteditLocationItem(item)
        seteditLocationIndex(index)
    }
    const onEditLocation = () => {
        let temp = [...data]
        temp[editLocationIndex] = editLocationItem
        dispatch(updateLocation(temp))
        seteditLocationItem(null)
    }
    return (
        <div className="bg-white p-5">
            {/* <Breadcrumb parent="" title="" /> */}
            <button onClick={() => {
                setTrueInput(true)
                seteditLocationItem(null)
            }} className="bg-[green] text-[#fff]  p-2 px-5 rounded-2xl text-lg ">Add Location</button>
            {trueInput ?
                <div>
                    <input onChange={(e) => setLocationName(e?.target?.value)} className="border w-full rounded-md focus:border mt-5 p-2 px-4 " placeholder="Enter your location" />
                    <div className="mt-3 flex gap-2">
                        <button onClick={addLocation} className="bg-[#1EC2E5] text-[#fff] p-1 px-3  rounded-2xl text-lg " >
                            Save
                        </button>
                        <button onClick={onCancel} className="bg-[#f4f7fedd] text-[#000] p-1 px-3 rounded-2xl text-lg ">
                            Cancel
                        </button>
                    </div>
                </div>
                : null
            }

            {!!editLocationItem ? <div>
                <p className="text-lg text-gray-500 font-semibold pt-4">Edit Location</p>
                <input
                    value={editLocationItem.name}
                    onChange={(e) => seteditLocationItem({ ...editLocationItem, name: e.target.value })}
                    className="border w-full rounded-md focus:border mt-3 p-2 px-4 " placeholder="Enter your location" />
                <div className="mt-3 flex gap-2">
                    <button onClick={onEditLocation} className="bg-[#1EC2E5] text-[#fff] p-1 px-3  rounded-2xl text-lg " >
                        Save
                    </button>
                    <button onClick={() => seteditLocationItem(null)} className="bg-[#f4f7fedd] text-[#000] p-1 px-3 rounded-2xl text-lg ">
                        Cancel
                    </button>
                </div>
            </div>
                : null}

            {/* <p className="text-[#A3AED0] border-b-2 border-[#DFE5F1] mt-3 pb-1">Added Location</p> */}
            {loading ?
                <Loader /> :
                <div className="flex flex-col mt-5">
                    {data?.map((location, index) => {
                        return (
                            <div className={`flex ${index <= data.length && 'border-b-2 border-b-[#F4F7FE]'} items-center justify-between py-2`}>

                                <p className="font-medium" key={location?.id}>{location?.name}</p>
                                <div className="flex flex-row space-x-5 items-center justify-normal">
                                    <div onClick={() => onEdit(location, index)}>
                                        <AiTwotoneEdit size={24} className="text-[#1EC2E5] cursor-pointer" />
                                    </div>
                                    <div onClick={() => onDelete(location, index)}>
                                        <RiDeleteBinLine size={24} className="text-red-600 cursor-pointer" />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}
export default Location;