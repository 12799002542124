import React, { Fragment, useState, useMemo, useCallback } from 'react';
import Breadcrumb from '../common/breadcrumb';
import differenceBy from 'lodash/differenceBy';
import { tableData } from '../../data/dummyTableData'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import { useEffect } from 'react';

const DataTableComponent = ({ tableData, columns, onRowClicked, pagination }) => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [data, setData] = useState([]);


  useEffect(() => {
    if (tableData.length !== data.length) {
      setData(tableData)
    }
  }, [tableData.length])



  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {

      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, 'name'));
        toast.success("Successfully Deleted !")
      }
    };

    return <button key="delete" onClick={handleDelete}>{"Delete"}</button>;
  }, [data, selectedRows, toggleCleared]);


  return (
    <Fragment>
      {/* <Breadcrumb title="Data Tables" parent="Table" /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">

              <div className=" datatable-react">
                <DataTable
                  columns={columns}
                  data={data}
                  border={false}
                  pagination={pagination}
                  // striped={true}
                  center={true}
                  onRowClicked={onRowClicked}
                  // selectableRows
                  persistTableHead
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                // clearSelectedRows={toggleCleared}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default DataTableComponent;