import { HttpService } from "../../services/httpService";
import { fetchDeviceDetailFailure, fetchDeviceDetailStart, fetchDeviceDetailSuccess } from "./deviceDetailSlice";


export const fetchDeviceDetailData = (account_id) => async (dispatch) => {
    try {
      dispatch(fetchDeviceDetailStart());
      const response = await HttpService.CoreApiGet(`assets/${account_id}`); 
      dispatch(fetchDeviceDetailSuccess(response?.data?.body));
    } catch (error) {
      dispatch(fetchDeviceDetailFailure(String(error))); 
    }
};
