import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    fetchLocationStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchLocationSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
    },
    fetchLocationFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setSensorFilterStart(state) {
      state.loading = true;
      state.error = null;
    },
    setSensorFilterSuccess(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchLocationStart,
  fetchLocationSuccess,
  fetchLocationFailure,
  setSensorFilterStart,
  setSensorFilterSuccess,
} = locationSlice.actions;

export default locationSlice.reducer;
