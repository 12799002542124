import { HttpService } from "../../services/httpService";
import {
  fetchLocationFailure,
  fetchLocationStart,
  fetchLocationSuccess,
  setSensorFilterStart,
  setSensorFilterSuccess,
} from "./locationSlice";

export const fetchLocationData = (getAllLocations=true) => async (dispatch) => {
  try {
    dispatch(fetchLocationStart());
    const response = await HttpService.CoreApiGet(`location/get`);
    if (getAllLocations) response?.data?.body.unshift({id: 0, name: 'All Locations', 'uuid': 'uuid'});
    dispatch(fetchLocationSuccess(response?.data?.body));
  } catch (error) {
    dispatch(fetchLocationFailure(String(error)));
  }
};

export const fetchDates = async () => {
  const response = await HttpService.CoreApiGet(`assets/filters`);
  return response?.data?.body?.datesDropdown;
};

export const applyFilter = () => async (dispatch, data) => {
  try {
    dispatch(setSensorFilterStart());
    const response = await HttpService.CoreApiPost(`assets/sensors`);
    dispatch(setSensorFilterSuccess(response?.data?.body));
  } catch (error) {
    // dispatch(fetchLocationFailure(String(error)));
  }
};

export const updateLocation = (data) => async (dispatch) => {
  try {
    console.log("AA", data)
    const response = await HttpService.CoreApiPost(`location/post`, data)
    console.log("DELETE LOCATION RESPONSE", response)
    dispatch(fetchLocationStart());
    const fetchLocationRes = await HttpService.CoreApiGet(`location/get`);
    dispatch(fetchLocationSuccess(fetchLocationRes?.data?.body));
  } catch (error) {
    console.log(error)
  }
}
