import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  token: '',
  tokenExpiration: null
};

const initialState = {
  value: initialStateValue,
};

const authSlice = createSlice({
  name: 'AuthState',
  initialState,
  reducers: {
    clear: (state) => {
      state.value = initialStateValue;
    },
    update: (state, action) => {
      state.value = action.payload;
    },
    updateToken: (state, action) => {
      state.value.token = action.payload;
    },
    updateExpiry: (state, action) => {
      state.value.tokenExpiration = action.payload;
    },
  },
});


export const { clear, updateToken, updateExpiry } = authSlice.actions;

export default authSlice.reducer;
