import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchDeviceDetailData } from "../../../../redux/deviceDetail/deviceDetailAction";
import DropdownSelect from "../../../base/DropDown/Dropdown";
import { Breadcrumb, Spinner } from "reactstrap";
import DataTableComponent from "../../../tables/dataTableComponent";
import { AiOutlineMore } from "react-icons/ai";
import LoaderOverlay from "../../../loader/loader";
import { HttpService } from "../../../../services/httpService";
import { toast } from "react-toastify";
import LineChart from "../../../charts/lineChart";
import { BiArrowBack } from "react-icons/bi";
import { renderAirQualityIcon } from "../../../utils";

const DeviceDetail = () => {
  const { account_id } = useParams();
  const state = useSelector((state) => state?.deviceDetail);
  const dispatch = useDispatch();
  const [model, setModel] = useState({
    id: "",
    location: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    floor: "",
    room: "",
  });
  const [airName, setAirName] = useState("pm25");
  const [period, setPeriod] = useState("1 day");
  const [interval, setInterval] = useState("5 minutes");
  const [fetchData, setFetchData] = useState({});
  const [graphData, setGraphData] = useState(null);
  const [initialLoad, setInitialLoad] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const [loader, setLoader] = useState(false);
  // const [co2Data,setCo2Data] = useState(null);
  const { data, loading, error } = state;
  const navigate = useNavigate();

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = date.getMonth();
    const month = monthNames[monthIndex];
    const day = date.getDate();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const minutes = date.getMinutes();
    const formattedDate = `${month} ${day}, ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;

    return formattedDate;
  }
  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    setModel({
      id: account_id,
      location: data?.last_readings?.locations?.name,
      street: data?.last_readings?.street,
      city: data?.last_readings?.city,
      state: data?.last_readings?.state,
      zip: data?.last_readings?.zip,
      floor: data?.last_readings?.floor,
      room: data?.last_readings?.room,
    });
  }, [data, account_id]);
  const deviceColumns = [
    {
      name: "Device",
      selector: (row) => row?.device_id,
      sortable: true,
      center: true,
    },
    {
      name: "Location",
      width: "200px",
      sortable: true,
      center: true,
      cell: (row) => <div>{row?.locations?.name || ""}</div>,
    },
    {
      name: "Last Connection",
      width: "200px",
      cell: (row) => <div>{formatTimestamp(row?.last_connection)}</div>,
      sortable: true,
      center: true,
    },
    /*{
      name: "Alert Setting Override",
      selector: (row) => row?.time,
      sortable: true,
      center: true,
    },*/
    {
      name: "PM 2.5",
      sortable: true,
      cell: (row) => renderAirQualityIcon(row?.PM_2_5_air_quality),
    },
    {
      name: "CO2",
      sortable: true,
      cell: (row) => renderAirQualityIcon(row?.co2_air_quality),
    },
    {
      name: "CO",
      sortable: true,
      cell: (row) => renderAirQualityIcon(row?.co_air_quality),
    },
    {
      name: "TVOC",
      sortable: true,
      cell: (row) => renderAirQualityIcon(row?.chemical_air_quality),
    },
    {
      name: "°F",
      sortable: true,
      cell: (row) => renderAirQualityIcon(row?.temperature_air_quality),
    },
    {
      name: "RH",
      sortable: true,
      cell: (row) => renderAirQualityIcon(row?.humidity_air_quality),
    },
    {
      name: "Mold",
      sortable: true,
      cell: (row) => renderAirQualityIcon(row?.temperature_air_quality),
    },
    {
      name: "Action",
      sortable: true,
      center: true,
      cell: (row) => (
        <div>
          <AiOutlineMore fontSize={"medium"} />
        </div>
      ),
    },
  ];
  useEffect(() => {
    dispatch(fetchDeviceDetailData(account_id));
  }, [account_id, dispatch]);

  const fillModel = (key, val) => {
    setModel({ ...model, [key]: val });
  };

  const lastReadingsArray = data?.last_readings ? [data?.last_readings] : [];

  const updateDevice = async (e) => {
    e.preventDefault();
    const response = await HttpService.CoreApiPost("assets/update", model)
      .then((success) => {
        console.log(success);
        toast.success("Data Updated sucessfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!initialLoad) {
      const fetchData = async () => {
        setLoader(true); // Set loading state to true when fetching data
        try {
          const response = await HttpService.CoreApiPost(`assets/sensors`, {
            filters: {
              locations: [Number(account_id)],
              until_date: new Date(),
              prior_period: period,
              interval: interval,
              readings: {
                pm25: true,
                co: true,
                co2: true,
                temperature: true,
                humidity: true,
                tvoc: true,
              },
            },
          });
          setFetchData(response?.data?.body);
          setInitialLoad(true);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoader(false); // Set loading state to false when data fetching is complete
        }
      };

      fetchData();
    }
  }, [account_id, initialLoad]);

  useEffect(() => {
    if (initialLoad) {
      // No need to access fetchData here
      setGraphData({
        data: fetchData?.[airName] || [], // Access graphData directly
        label: fetchData?.label || "",
      });
    }
  }, [airName, fetchData, initialLoad]);

  const handlePeriodOnChange = (event) => {
    setPeriod(event);
    setInitialLoad(false);
    event === "1 day"
      ? setInterval("5 minutes")
      : event === "1 week"
      ? setInterval("60 minutes")
      : setInterval("120 minutes");
  };

  return (
    <>
      {loading && loader ? (
        <LoaderOverlay />
      ) : (
        <>
          <Breadcrumb parent="" title="" />
          <div className="px-[10px]">
            <div
              className="flex px-1 py-2 justify-center items-center bg-white rounded-md cursor-pointer w-[30px]"
              onClick={() => navigate("/dashboard")}
            >
              <BiArrowBack className=" text-xl" />
            </div>
          </div>
          <div className="p-1">
            <div className="bg-white rounded-lg ">
              <div className="mt-3">
                <DataTableComponent
                  tableData={lastReadingsArray || []}
                  columns={deviceColumns}
                />
              </div>
            </div>
          </div>
          <div className="flex mt-3 border px-5 bg-white lg:justify-start">
            <div className="flex gap-2 items-center">
              <p className="text-base font-normal">Period:</p>
              <DropdownSelect
                onChange={(e) => handlePeriodOnChange(e)}
                data={["1 day", "1 week", "1 month", "3 months", "1 year"]}
                title="1 day"
              />
            </div>
          </div>
          <div className="mt-3 border p-2 bg-white ">
            <div className="grid grid grid-cols-1 md:grid-cols-4  lg:grid-cols-6 gap-2 m-4">
              <button
                onClick={() => setAirName("pm25")}
                className={`cursor-pointer p-2 rounded-md ${
                  airName === "pm25"
                    ? "bg-[#1EC2E5] text-[#fff]"
                    : "bg-[#F4F7FE] text-[#A3AED0]"
                }`}
              >
                Particulates (PM 2.5)
              </button>
              <button
                onClick={() => setAirName("co2")}
                className={`cursor-pointer  p-2 rounded-md ${
                  airName === "co2"
                    ? "bg-[#1EC2E5] text-[#fff]"
                    : "bg-[#F4F7FE] text-[#A3AED0]"
                }`}
              >
                Carbon Dioxide (CO2)
              </button>
              <button
                onClick={() => setAirName("co")}
                className={`cursor-pointer p-2 rounded-md ${
                  airName === "co"
                    ? "bg-[#1EC2E5] text-[#fff]"
                    : "bg-[#F4F7FE] text-[#A3AED0]"
                }`}
              >
                Carbon Monoxide (CO)
              </button>
              <button
                onClick={() => setAirName("tvoc")}
                className={`cursor-pointer  p-2 rounded-md ${
                  airName === "tvoc"
                    ? "bg-[#1EC2E5] text-[#fff]"
                    : "bg-[#F4F7FE] text-[#A3AED0]"
                }`}
              >
                Chemicals (TVOC)
              </button>
              <button
                onClick={() => setAirName("humidity")}
                className={`cursor-pointer p-2 rounded-md ${
                  airName === "humidity"
                    ? "bg-[#1EC2E5] text-[#fff]"
                    : "bg-[#F4F7FE] text-[#A3AED0]"
                }`}
              >
                Humidity (RH)
              </button>
              <button
                onClick={() => setAirName("temperature")}
                className={`cursor-pointer p-2 rounded-md ${
                  airName === "temperature"
                    ? "bg-[#1EC2E5] text-[#fff]"
                    : "bg-[#F4F7FE] text-[#A3AED0]"
                }`}
              >
                Temperature (°F)
              </button>
            </div>
            {loader ? (
              <div className="flex justify-center p-10">
                <Spinner size="large" variant="primary" />
              </div>
            ) : (
              graphData && (
                <div className="min-w-[250px] min-h-[350px]">
                  <LineChart json={graphData} />
                </div>
              )
            )}
          </div>
          <div className="w-full ">
            <div className="p-1 rounded-md divide-y  ">
              <div className="accordion-item">
                {activeIndex === 0 && (
                  <div className="accordion-item-content mt-3 px-4 py-4 bg-white">
                    <form onSubmit={updateDevice}>
                      <div className="flex justify-end">
                        <button
                          className="border p-1 px-5 rounded-md"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                      <div className="grid grid-cols-4 justify-between gap-2">
                        <div className="mt-2 w-full">
                          <p>Location:</p>
                          <input
                            value={model?.location}
                            onChange={(e) =>
                              fillModel("location", e.target.value)
                            }
                            className="border p-2 rounded-md mt-2 w-full"
                          />
                        </div>
                        <div className="mt-2 w-full">
                          <p>Street:</p>
                          <input
                            value={model?.street}
                            onChange={(e) =>
                              fillModel("street", e.target.value)
                            }
                            className="border p-2 rounded-md mt-2 w-full"
                          />
                        </div>
                        <div className="mt-2 w-full">
                          <p>City:</p>
                          <input
                            value={model?.city}
                            onChange={(e) => fillModel("city", e.target.value)}
                            className="border p-2 rounded-md mt-2 w-full"
                          />
                        </div>
                        <div className="mt-2 w-full">
                          <p>State:</p>
                          <input
                            value={model?.state}
                            onChange={(e) => fillModel("state", e.target.value)}
                            className="border p-2 rounded-md mt-2 w-full"
                          />
                        </div>
                        <div className="mt-2 w-full">
                          <p>Zip:</p>
                          <input
                            value={model?.zip}
                            onChange={(e) => fillModel("zip", e.target.value)}
                            className="border p-2 rounded-md mt-2 w-full"
                          />
                        </div>
                        <div className="mt-2 w-full">
                          <p>Floor:</p>
                          <input
                            value={model?.floor}
                            onChange={(e) => fillModel("floor", e.target.value)}
                            className="border p-2 rounded-md mt-2 w-full"
                          />
                        </div>
                        <div className="mt-2 w-full">
                          <p>Room:</p>
                          <input
                            value={model?.room}
                            onChange={(e) => fillModel("room", e.target.value)}
                            className="border p-2 rounded-md mt-2 w-full"
                          />
                        </div>
                        <div className="mt-2 w-full">
                          <p>Over Alert Setting</p>
                          <input
                            disabled
                            value={data?.last_readings?.over_alert_setting}
                            className="border p-2 rounded-md mt-2"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DeviceDetail;
