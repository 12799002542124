import axios from "axios";
import { API_URL } from "../url/url";
import { UserService } from "./userservice";

export class HttpService {
    static async Post(endpoint, body) {
        if (endpoint.startsWith('/'))
            throw new Error("Endpoint should starts with /")

        let token = UserService.GetAccessToken();
        return await axios.post(`${API_URL}/${endpoint}`, body, token ? {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        } : {});
    }

    static async Get(endpoint) {
        if (endpoint.startsWith('/'))
            throw new Error("Endpoint should not starts with /")

        let token = UserService.GetAccessToken();
        return await axios.get(`${API_URL}/${endpoint}`, token ? {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        } : {});
    }

    static async CoreApiGet(endpoint) {
        if (endpoint.startsWith('/'))
            throw new Error("Endpoint should not starts with /")

        let token = UserService.GetAccessToken();
        return await axios.get(`${API_URL}/${endpoint}`, token ? {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        } : {});
    }

    static async CoreApiGetCalender(endpoint, requestBody = null) {
        if (endpoint.startsWith('/'))
            throw new Error("Endpoint should not start with /");

        let token = UserService.GetAccessToken();

        return await axios.get(`${API_URL}/${endpoint}`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            },
            data: requestBody,
        });
    }

    static async CoreApiPost(endpoint, body) {
        if (endpoint.startsWith('/'))
            throw new Error("Endpoint should starts with /")

        let token = UserService.GetAccessToken();
        return await axios.post(`${API_URL}/${endpoint}`, body, token ? {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        } : {});
    }

    static async CoreApiPostForCancel(endpoint, body, cancelToken) {
        if (endpoint.startsWith('/'))
            throw new Error("Endpoint should start with /");
    
        let token = UserService.GetAccessToken();
    
        // Create the request configuration object
        const config = {
            headers: {
                Authorization: token ? `Bearer ${token}` : undefined,
            },
            cancelToken,
        };
    
        return await axios.post(`${API_URL}/${endpoint}`, body, config);
    }
    
    static async CoreApiPostWithoutBody(endpoint) {
        if (endpoint.startsWith('/')) {
            throw new Error("Endpoint should start with /");
        }

        let token = UserService.GetAccessToken();
        return await axios.post(`${API_URL}/${endpoint}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }
}
