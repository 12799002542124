import { HttpService } from "../../services/httpService";
import { fetchSettingFailure, fetchSettingStart, fetchSettingSuccess } from "./settingSlice";


export const fetchSettingtData = () => async (dispatch) => {
    try {
      dispatch(fetchSettingStart());
      const response = await HttpService.CoreApiGet('settings/2');  // TODO: need to remove this hardcoding
      dispatch(fetchSettingSuccess(response?.data?.body));
    } catch (error) {
      dispatch(fetchSettingFailure(String(error))); 
    }
};
